import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,Label,FormGroup,Input } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createPark,parkList,editPark,getDistrictDropdown,getThanaDropdown,getDivisionDropdown,leaseHolderSignUpload} from '../../../store/parkInfo/actions';

class ParkInfoCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                Park: {
                    
                },
                lease_holder_sign:{},

            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    districtHandleChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
         this.fetchDistrict(event.target.value);
        // alert(event.target.value);
        //  this.updateEmployeeObject(name, value);
      }
      
    thanaHandleChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
         this.fetchThana(event.target.value);
        //  this.updateEmployeeObject(name, value);
      }
      
fetchDistrict = (value) => {

  getDistrictDropdown(value)
      .then((response) => {
          this.setState({
              district: response.data
          })
      }, (respose) => {

      })
      .catch((err) => {
          console.log(err);
      })
}


fetchThana = (value) => {

  getThanaDropdown(value)
      .then((response) => {
           console.log("thana",response);
          this.setState({
              thana: response.data
          })
      }, (respose) => {

      })
      .catch((err) => {
          console.log(err);
      })

}

fetchDivision = () => {

    getDivisionDropdown()
        .then((response) => {
             console.log("division",response);
            this.setState({
                division: response.data
            })
        }, (respose) => {
  
        })
        .catch((err) => {
            console.log(err);
        })
  
  }

    componentDidMount()
    {
            this.fetchparkList();   
            
            this.fetchDivision();         
    }

    fetchparkList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        parkList(options).then((response) => {
            this.setState({
                parkList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (Park) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.Park = Park;
        this.fetchDistrict(Park.f_division_id);
        this.fetchThana(Park.f_zilla_id);
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }


      autoSearchInputChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
        // this.updateDesignationObject(name, value);
      }
      
      // handleValidSubmit
        handleValidSubmit(event, values) {       
            this.props.createPark(values);

            this.form && this.form.reset();
            this.fetchparkList();    
        }
       
       handleEditSubmit(event, values) {  
            this.props.editPark(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
             this.fetchparkList();  
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchparkList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchparkList();
            });
        }

        //image save in local storage start
        handleImageUpload = (e) => {
            var id = e.target.id;
            var parkInfo_id = this.state.Park.id;
            let files = e.target.files;
    
                let reader = new FileReader(); 
                reader.readAsDataURL(files[0]); 
                reader.onload = (e)  => {
                    const formData = {file: e.target.result, name:files[0].name, type:files[0].type ,parkInfo_id: parkInfo_id};
    
                    leaseHolderSignUpload(formData,formData.parkInfo_id).then((response) => { 
                        // this.state.lease_holder_sign = response.data.ParkInfo.lease_holder_sign;
                        this.fetchparkList();     
                        // this.setState({
                        //     lease_holder_sign : response.data.ParkInfo.lease_holder_sign, 
                        // });  
    
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                }
           }
        //image save in local storage end


    render() {
        let divisionList = [];
        let districtList = [];
        let thanaList = [];

        if (this.state.hasOwnProperty('division')) {
            divisionList =  this.state.division;
          }

        if (this.state.hasOwnProperty('district')) {
            districtList =  this.state.district;
          }

        if (this.state.hasOwnProperty('thana')) {
            thanaList =  this.state.thana;
          }
        let parkList = [];
        let paginator;
        if (this.state.hasOwnProperty('parkList')) {
            parkList = this.state.parkList.data;
            paginator = {
                'currentPage':this.state.parkList.currentPage,
                'pagesCount':this.state.parkList.pagesCount,
                'total':this.state.parkList.total,
                'to':this.state.parkList.to,
                'from':this.state.parkList.from,
            }
        }

        const imageLink =process.env.REACT_APP_IMAGE_URL;
        const {id,park_name_en,park_name_bn,f_division_id,f_zilla_id,f_thana_id,address,priority,status,ushulkari,lease_year,memorandum_no,lease_holder,lease_holder_sign} = this.state.Park;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Park Info" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Park </CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Park Name"  id="park_name_en" name="park_name_en"  required/>
                                                     {/* <AvField name="id" id="id" value={id}  type="hidden"  /> */}
                                                </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Park Name(Bangla)"  id="park_name_bn" name="park_name_bn" />
                                                 </div>


                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Lease Holder"  id="lease_holder" name="lease_holder" required/>
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="file" label="Lease Holder sign"  id="lease_holder_sign" name="lease_holder_sign" onChange={(e) =>this.handleImageUpload(e)}  />
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Memorandum No"  id="memorandum_no" name="memorandum_no" required/>
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Lease Year"  id="lease_year" name="lease_year"  required/>
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Ushulkari"  id="ushulkari" name="ushulkari"  />
                                                 </div>


                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="textarea" label="Address"  id="address" name="address" required/>
                                                 </div>
                                                
                                                 <div className="form-group  col-md-12 ">
                                                        <AvField type="select"className="custom-select" label="Select Division" name="f_division_id" id="f_division_id" onChange={(e) => this.districtHandleChange(e)} className="form-control" required>
                                                                <option defaultValue>Select Division</option>
                                                                {divisionList.map((division, index) =>
                                                            <option  value={division.division_id}>{division.division_name}</option>
                                                        )}
                                                        </AvField>
                                                    </div>
                                                    <div className="form-group col-md-12 " >
                                                        <AvField type="select" className="custom-select" label="Select District" name="f_zilla_id" id="f_zilla_id"  onChange={(e) => this.thanaHandleChange(e)} className="form-control" required>
                                                            <option defaultValue>Select District</option>
                                                                {districtList.map((district, index) =>
                                                            <option value={district.district_id}>{district.district_name}</option>
                                                        )}
                                                        </AvField>
                                                    </div>

                                                    <div className="form-group col-md-12 " >
                                                        <AvField type="select" className="custom-select" label="Select Thana" name="f_thana_id" id="f_thana_id" onChange={(e) => this.autoSearchInputChange(e)} className="form-control" required>
                                                            <option defaultValue>Select Thana</option>
                                                            {thanaList.map((thana, index) =>
                                                            <option value={thana.thana_id}>{thana.thana_name}</option>
                                                        )}
                                                        </AvField>
                                                    </div>

                                                   <div className="form-group col-md-12 " >
                                                        <AvField type="select" className="custom-select" label="Status" name="status" id="Status" onChange={(e) => this.autoSearchInputChange(e)} className="form-control" required>
                                                            <option defaultValue>Select Status</option>
                                                            <option value="1">active</option>
                                                            <option value="2">Deactive</option>
                                                        </AvField>
                                                    </div>

                                                    <div className="form-group col-md-12 ">
                                                         <AvField className="form-control" type="text" label="Priority"  id="priority" name="priority"  required/>
                                                    </div>

                                                    

                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                        </Col>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Park List</CardTitle>
                                        {/* <div className="table-responsive-sm"> */}
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0 table-responsive">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Park Name</th>
                                                        <th>Address</th>
                                                        <th>Division</th>
                                                        <th>District</th>
                                                        <th>Thana</th>
                                                        <th>Lease Holder sign</th>
                                                        <th>Priority</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {parkList.map((park, index) =>
                                                    <tr key={park.id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{park.park_name_en}</td>
                                                        <td>{park.address}</td>
                                                        <td>{park.division== null?park.f_division_id:park.division.division_name}</td>
                                                        <td>{park.district==null?park.f_zilla_id:park.district.district_name}</td>
                                                        <td>{park.thana==null?park.f_thana_id:park.thana.thana_name}</td>
                                                        <td>{
                                                             <img src={imageLink+'leaseHolderSign/'+park.lease_holder_sign} alt="" height="50" />
                                                            }
                                                        </td>
                                                        
                                                        <td>{park.priority}</td>

                                                        
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(park) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        {/* </div> */}
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{park_name_en}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               ParkInfo Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Park Name"  id="park_name_en" name="park_name_en" value={park_name_en} required/>
                                                     <AvField name="id" id="id" value={id}  type="hidden"  />
                                                </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Park Name(Bangla)"  id="park_name_bn" name="park_name_bn" value={park_name_bn} />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Lease Holder"  id="lease_holder" name="lease_holder" value={lease_holder}  required/>
                                                 </div>
                                                 <div className="col-md-12">
                                                    <img src={imageLink+'leaseHolderSign/'+lease_holder_sign} alt="" height="50" />

                                                     <AvField className="form-control" type="file" label="Lease Holder sign"  id="lease_holder_sign" name="lease_holder_sign" onChange={(e) =>this.handleImageUpload(e)}  />
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Memorandum No"  id="memorandum_no" name="memorandum_no" value={memorandum_no} required/>
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Lease Year"  id="lease_year" name="lease_year" value={lease_year}  required/>
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Ushulkari"  id="ushulkari" name="ushulkari" value={ushulkari} />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="textarea" label="Address"  id="address" name="address" value={address} required/>
                                                 </div>
                                                 <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Select Division" name="f_division_id" id="f_division_id" value={f_division_id}  onChange={(e) => this.districtHandleChange(e)} className="form-control" required>
                                                            <option value=''>Select Division</option>
                                                            {divisionList.map((division, index) =>
                                                        <option  value={division.division_id}>{division.division_name}</option>
                                                      )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <AvField type="select" className="custom-select" label="Select District" name="f_zilla_id" id="f_zilla_id" value={f_zilla_id}  onChange={(e) => this.thanaHandleChange(e)} className="form-control" required>
                                                        <option value=''>Select District</option>
                                                            {districtList.map((district, index) =>
                                                        <option  value={district.district_id}>{district.district_name}</option>
                                                      )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-12" >
                                                    <AvField type="select" className="custom-select" label="Select Thana" name="f_thana_id" id="f_thana_id" value={f_thana_id}  onChange={(e) => this.autoSearchInputChange(e)} className="form-control" required>
                                                        <option value=''>Select Thana</option>
                                                        {thanaList.map((thana, index) =>
                                                        <option  value={thana.thana_id}>{thana.thana_name}</option>
                                                      )}
                                                    </AvField>
                                                </div>

                                                    <div className="form-group col-md-12 " >
                                                        <AvField type="select" className="custom-select form-control" label="Status" name="status" id="Status" value={status} required  >
                                                            <option value=''>Select Status</option>
                                                            <option value="1">active</option>
                                                            <option value="2">Deactive</option>
                                                        </AvField>
                                                    </div>

                                                    <div className="form-group col-md-12 ">
                                                         <AvField className="form-control" type="text" label="Priority"  id="priority" name="priority"  value={priority} required/>
                                                    </div>
                                               
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingSpaceName;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createPark,editPark })(ParkInfoCreate));
