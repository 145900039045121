import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,Input, FormGroup,UncontrolledTooltip } from "reactstrap";
import { AvForm, AvField,AvInput ,AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createParkIn,parkOutList,editParkIn,searchByRateTypeList,vehicleTypeList,rateTypeList,parkInList,removeParkIn} from '../../../store/park/actions';
import { parkingSpaceNameList} from '../../../store/parkingSpaceName/actions';
import { parkingSlotList} from '../../../store/parkingSlot/actions';

import logo from "../../../assets/images/logo.svg";

class ParkingIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                f_vehicle_type_id: null,
                f_parking_space_name_id: null,
                f_rate_type_id: 1,
                first_hour_rate: 0,
                per_hour_rate: 0,
                fixed_rate: 0,
                parkInList: {
                    
                },
                parkIn:{
                    vehicle_type: {}
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchparkOutList();     
            this.fetchVehicleTypeList();     
            this.fetchparkingSpaceNameList();     
            this.fetchparkingSlotList();     
            this.fetchRateTypeList();     
            this.fetchparkInList();     
    }

    fetchparkInList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        parkInList(options).then((response) => {
            this.setState({
                parkInList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchRateTypeList = () => {
        rateTypeList().then((response) => {
            this.setState({
                rateTypeList : response.data.rateType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchparkingSlotList = () => {
        parkingSlotList().then((response) => {

            this.setState({
                parkingSlotList :response.data.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchParkingRate = ( vehicle_type , space_name , rate_type) => {
        searchByRateTypeList(vehicle_type , space_name , rate_type).then((response) => {
            if(response.success == true){
                this.setState({
                    parkingRateType : response.data, 
                    first_hour_rate : response.data.entry_first_hour_rate, 
                    per_hour_rate : response.data.per_hour_rate, 
                    fixed_rate : response.data.fixed_rate, 
                });
            }else{
                alert(response.message);
                this.setState({
                    first_hour_rate : '', 
                    per_hour_rate : '', 
                    fixed_rate : '', 
                });
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchVehicleTypeList = () => {
        vehicleTypeList().then((response) => {
            this.setState({
                vehicleTypeList : response.data.vehicleType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchparkingSpaceNameList = () => {
        parkingSpaceNameList().then((response) => {
            this.setState({
                parkingSpaceNameList :response.data.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchparkOutList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        parkOutList(options).then((response) => {
            this.setState({
                parkOutList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (parkIn) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        if(parkIn.hasOwnProperty('id')){
            this.state.parkIn = parkIn;
            console.log("parkIn",parkIn)
        }
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      handleSelectData = (event, value) => { 
         if(event.target.name == 'f_parking_space_name_id'){
            this.setState({
                f_parking_space_name_id : value
            });
            this.state.f_parking_space_name_id = value;
         }else if(event.target.name == 'f_vehicle_type_id'){
             var vehicle= event.target.id;
            this.setState({
                f_vehicle_type_id : vehicle
            });
            this.state.f_vehicle_type_id = vehicle;
        }

         var f_vehicle_type_id = this.state.f_vehicle_type_id;
         var f_parking_space_name_id = this.state.f_parking_space_name_id;
         var f_rate_type_id = this.state.f_rate_type_id;
         if(f_parking_space_name_id != null && f_parking_space_name_id != '' && f_vehicle_type_id != null && f_vehicle_type_id != '' )
        this.fetchParkingRate(f_vehicle_type_id,f_parking_space_name_id,f_rate_type_id);

      }

      format(date) {
        var d = date.getDate();
        var m = date.getMonth() + 1;
        var y = date.getFullYear();
        return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }
    

      // handleValidSubmit
        handleValidSubmit(event, values) {
            
            var date_time = new Date(); 

            values.tran_start_date_time =  this.format(date_time)+' '+date_time.getHours()+':'+date_time.getMinutes()+':'+date_time.getSeconds();   
            values.tran_start_date   =  this.format(date_time);     
            values.tran_start_time  =   date_time.getHours()+':'+date_time.getMinutes()+':'+date_time.getSeconds();    
            values.token_no = Math.floor(1000 + Math.random() * 9000);       
            values.device_id = 'web';       
            values.f_in_out_info = 1;       
            values.f_rate_type_id = 1;       
            values.f_vehicle_type_id = this.state.f_vehicle_type_id;       
            values.first_hourly_rate = this.state.first_hour_rate;       
            values.per_hour_rate = this.state.per_hour_rate;       
            values.fixed_rate = this.state.fixed_rate;   
            if(values.f_vehicle_type_id == null || values.f_vehicle_type_id == ''){
                alert('Please Select Vehicle Type!');  
            }else{  
                this.props.createParkIn(values);
                this.form && this.form.reset();
                this.fetchparkInList();
            }

        }

       
       handleEditSubmit(event, values) {  
           var x = window.confirm('Are you sure to delete?');
           if(x == true){
                this.props.removeParkIn(values);
                this.setState(prevState => ({
                    modal_scroll: !prevState.modal_scroll,
                }));
                setTimeout(() =>
                    this.fetchparkInList(),
                    800
                  );
           }
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchparkInList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchparkInList();
            });
        }

    render() {
        let vehicleTypeList = [];
        let rateTypeList = [];
        let parkingSpaceNameList = [];
        let parkingSlotList = [];
        const {fixed_rate  , first_hour_rate , per_hour_rate } = this.state ;
        const {id,token_no,vehicle_name,f_parking_space_name_id,f_rate_type_id,f_vehicle_type_id,device_id,tran_start_date_time,vehicle_type,vehicleType,f_client_id,first_hourly_rate} = this.state.parkIn;

        if (this.state.hasOwnProperty('vehicleTypeList')) { vehicleTypeList = this.state.vehicleTypeList; }
        if (this.state.hasOwnProperty('rateTypeList')) { rateTypeList = this.state.rateTypeList; }
        if (this.state.hasOwnProperty('parkingSpaceNameList')) { parkingSpaceNameList = this.state.parkingSpaceNameList; }
        if (this.state.hasOwnProperty('parkingSlotList')) { parkingSlotList = this.state.parkingSlotList; }
        
        let parkInList = [];
        let paginator;
        if (this.state.parkInList.hasOwnProperty('data')) {
            parkInList = this.state.parkInList.data;
            paginator = {
                'currentPage':this.state.parkInList.current_page,
                'pagesCount':this.state.parkInList.last_page,
                'total':this.state.parkInList.total,
                'to':this.state.parkInList.to,
                'from':this.state.parkInList.from,
            }
        }
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        let image_url = process.env.REACT_APP_IMAGE_URL ;

        const shade = {
            // width: '100%',
            // height: '100%',
            background: '#FF8040',
            borderRadius: '20%',
            // position: 'absolute',
            // top: '0',
            // left: '0',
          }

          const gateTime = (tran_start_date_time,grace_period) =>{
              let minutes = Math.abs(new Date() -new Date(tran_start_date_time))/1000/60;
              let hours = (minutes > grace_period)?(minutes - grace_period)/60:(minutes)/60;
              if(hours%1 > 0.00 &&  hours%1 < 0.51){
                  hours = Math.floor(hours)+1;
              }else{
                hours = Math.floor(hours);
              }
              return hours
          }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Park In" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Park In</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>

                                        {vehicleTypeList.map((vehicle, index) =>
                                        
                                        <label class="radio-img">
                                            <input type="radio" name="f_vehicle_type_id" id={vehicle.id} onChange={(e,v) => { this.handleSelectData(e,v) }} />&nbsp;&nbsp;&nbsp;&nbsp;
                                            <img style={(this.state.f_vehicle_type_id ==vehicle.id)?shade:{}} src={vehicle.icon_image == null?logo:image_url+'vehicle/'+vehicle.icon_image} alt="" height="60" width="60" /><p style={{textAlign: 'center'}}>&nbsp;&nbsp;&nbsp;&nbsp;{vehicle.vehicle_type_name_en}</p>
                                        </label>
                                                             )}
                                        
                                        <AvField className="form-control"  type="hidden" name="f_vehicle_type_id" id="f_vehicle_type_id" value={this.state.f_vehicle_type_id}/>

                                            <FormGroup row>
                                                 
                                                 <Col lg="3" md="3">
                                                      <AvField className="form-control" label="Vehicle No"  type="text" name="vehicle_name" id="vehicle_name" required/>
                                                  </Col>
                                                 
                                                  <Col lg="3" md="3">
                                                        <AvField type="select"className="custom-select" label="Parking Space" name="f_parking_space_name_id" onChange={(e,v) => { this.handleSelectData(e,v) }} id="f_parking_space_name_id" required>
                                                                <option defaultValue>Select</option>
                                                                {parkingSpaceNameList.map((space, index) =>
                                                                    <option value={space.id}>{space.space_name_en}</option>
                                                                )}
                                                        </AvField>
                                                  </Col>
                                                 
                                                 
                                                <AvField className="form-control" type="hidden" name="f_rate_type_id" id="f_rate_type_id" value={this.state.f_rate_type_id} />

                                                <Col lg="3" md="3">
                                                    <AvField type="select"className="custom-select" label="Parking Slot" name="f_parking_slot_id" id="f_parking_slot_id" required>
                                                                <option defaultValue>Select</option>
                                                            {parkingSlotList.map((slot, index) =>
                                                                <option value={slot.id}>{slot.parking_slot_name_bn}</option>
                                                            )}
                                                    </AvField>
                                                </Col>
                                            
                                                
                                                 <Col lg="1" md="1">
                                                     <label>First Hour </label>
                                                     <Input className="form-control" label="First Hour Rate"  type="number" name="first_hourly_rate" id="first_hourly_rate" value={first_hour_rate} readOnly />
                                                     <AvField  type="hidden" name="first_hourly_rate" id="first_hourly_rate" value={first_hour_rate}  />
                                                 </Col>

                                                <Col lg="1" md="1">
                                                    <label>Per Hour </label>
                                                    <Input className="form-control" label="Per Hour Rate"  type="number" name="per_hour_rate" id="per_hour_rate" value={per_hour_rate} readOnly />
                                                     <AvField  type="hidden" name="per_hour_rate" id="per_hour_rate" value={per_hour_rate}  />
                                                </Col>
                                                <Col lg="1" md="1">
                                                    <label>Fixed Rate </label>
                                                     <Input className="form-control" label="Fixed Rate"  type="number" name="fixed_rate" id="fixed_rate" value={fixed_rate} readOnly />
                                                     <AvField  type="hidden" name="fixed_rate" id="fixed_rate" value={fixed_rate}  />
                                                </Col>
                                            </FormGroup>


                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="12" md="12">
                            <Card>
                                    <CardBody>
                                        <CardTitle>Park In List <Badge color={'success'}>{paginator== null?0:this.state.parkInList.total}</Badge> 
                                        <Link to="/park/deletedlist" style={{marginLeft: '80%'}}> Deleted List</Link>
                                         </CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0 table-responsive">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Code</th>
                                                        <th>Token</th>
                                                        <th>Vehicle</th>
                                                        <th>Type</th>
                                                        <th>Entry Time</th>
                                                        <th>Current Time</th>
                                                        <th>Approx Spent Time</th>
                                                        <th>Approx Bill Amount</th>
                                                        <th>Device</th>
                                                        <th>In By</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {parkInList.map((parkIn, index) =>
                                                    <tr key={parkIn.id .toString()}>
                                                    <td scope="row">{index+1}</td>
                                                    <td>{parkIn.tran_code}</td>
                                                    <td>{parkIn.token_no}</td>
                                                    <td>{parkIn.vehicle_name == null?'':parkIn.vehicle_name.substr(0,2)+'-'+parkIn.vehicle_name.substr(2)}</td>
                                                    <td>{parkIn.vehicle_type == null? parkIn.f_vehicle_type_id: parkIn.vehicle_type.vehicle_type_name_en}</td>
                                                    <td>{new Date(parkIn.tran_start_date_time).toLocaleString()}</td>
                                                    <td>{new Date().toLocaleString()}</td>
                                                    <td>{gateTime(parkIn.tran_start_date_time,parkIn.vehicle_type.grace_period)} Hour</td>
                                                    <td>{gateTime(parkIn.tran_start_date_time,parkIn.vehicle_type.grace_period)*1 > parkIn.vehicle_type.charge_count_hour*1 ?parkIn.first_hourly_rate*1 + (gateTime(parkIn.tran_start_date_time,parkIn.vehicle_type.grace_period)*1 - parkIn.vehicle_type.charge_count_hour*1)*parkIn.per_hour_rate*1: parkIn.first_hourly_rate*1} Tk.</td>
                                                    <td><Badge color={(parkIn.device_id == 'web')?'success':'info'}>{(parkIn.device_id == 'web')?'Web':'Android'}</Badge></td>
                                                    <td>{parkIn.insert_user==null?parkIn.insert_user_id:parkIn.insert_user.name}</td>


                                                    <td>
                                                        <Button
                                                        onClick={() => { this.tog_scroll(parkIn) }} 
                                                        data-toggle="modal"
                                                        className="btn btn-sm btn-success">Details</Button>
                                                    </td>
                                                   </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal 
                                className={"modal-dialog-centered"}
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">Token {token_no}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Rate Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg="4">
                                                                <div className="text-lg-center">
                                                                    {
                                                                        
                                                                        vehicle_type.icon_image !== null
                                                                            ? <img src={ image_url+'vehicle/'+vehicle_type.icon_image} className="avatar-sm mr-3 rounded-circle mb-4 float-left float-lg-none" alt="img" />
                                                                            : <div className="avatar-sm mr-3 mx-lg-auto mb-4 float-left float-lg-none">
                                                                                <span className={"avatar-title rounded-circle bg-soft-green text-primary font-size-16"}>
                                                                                    {vehicle_type.vehicle_type_name_en.charAt(0)}
                                                                                </span>
                                                                            </div>
                                                                    }

                                                                    <h5 className="mb-1 font-size-15 text-truncate">{vehicle_name == null?'':vehicle_name.substr(0,2)+'-'+vehicle_name.substr(2)}</h5>
                                                                    <Badge color={(device_id == 'web')?'success':'info'}>Device: {(device_id == 'web')?'Web':'Android'}</Badge>
                                                                    
                                                                </div>
                                                            </Col>

                                                            <Col lg="8">
                                                                <div>
                                                                    <Link to="#" className="d-block text-primary mb-2">Token #{token_no}</Link>
                                                                    <h5 className="text-truncate mb-4 mb-lg-5">{vehicle_type.vehicle_type_name_en}</h5>
                                                                    <ul className="list-inline mb-0">
                                                                        
                                                                        <li className="list-inline-item">
                                                                            <h5 className="font-size-14" id="duedateTooltip"><i className="bx bx-calendar mr-1 text-primary"></i>
                                                                                {new Date(tran_start_date_time).toLocaleString()}
                                                                                <UncontrolledTooltip placement="top" target="duedateTooltip">
                                                                                   Start Date Time
                                                                                        </UncontrolledTooltip>
                                                                            </h5>
                                                                        </li>
                                                                        <br></br>
                                                                        <li className="list-inline-item ">
                                                                            <h5 className="font-size-14" id="timeTooltip">
                                                                                <i className="bx bx-time mr-1 text-primary"></i>
                                                                                                 {gateTime(tran_start_date_time,vehicle_type.grace_period)} hour
                                                                                <UncontrolledTooltip placement="top" target="timeTooltip">
                                                                                    Approx Time
                                                                                                </UncontrolledTooltip>
                                                                            </h5>
                                                                        </li>
                                                                        <br></br>
                                                                        <li className="list-inline-item ">
                                                                            <h5 className="font-size-14" id="amountTooltip">
                                                                                <i className="bx bx-money mr-1 text-primary"></i>
                                                                                                 {gateTime(tran_start_date_time,vehicle_type.grace_period)*1 > vehicle_type.charge_count_hour*1 ?first_hourly_rate*1 + (gateTime(tran_start_date_time,vehicle_type.grace_period)*1 - vehicle_type.charge_count_hour*1)*this.state.parkIn.per_hour_rate*1: first_hourly_rate*1} Tk.
                                                                                <UncontrolledTooltip placement="top" target="amountTooltip">
                                                                                    Approx Bill
                                                                                                </UncontrolledTooltip>
                                                                            </h5>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                <AvField  type="hidden" name="id" id="id" value={id}  />

                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-danger save-event">Delete</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingTransaction;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createParkIn,editParkIn,removeParkIn })(ParkingIn));
