import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,UncontrolledTooltip,FormGroup } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {deletedTicketList,restoreTicket} from '../../../store/parkTicketTransaction/actions';
import { parkingSpaceNameList} from '../../../store/parkingSpaceName/actions';
import Loader from "../../../pages/Loader/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';



class TicketDeletedList extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                loading: true,
                startDate: new Date(),
                endDate: new Date(),
                parkTicktList: {
                    
                },
                parkIn:{
                    vehicle_type: {}
                },
                parkTicketList: [],
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchDeletedTicketList();     
         //   this.fetchRateTypeList();     
           // this.fetchVehicleTypeList();     
           // this.fetchparkingSpaceNameList();     
    }

    // fetchRateTypeList = () => {
    //     rateTypeList().then((response) => {
    //         this.setState({
    //             rateTypeList : response.data.rateType, 
    //         });
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     })
    // }

    // fetchVehicleTypeList = () => {
    //     vehicleTypeList().then((response) => {
    //         this.setState({
    //             vehicleTypeList : response.data.vehicleType, 
    //         });
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     })
    // }

    fetchparkingSpaceNameList = () => {
        parkingSpaceNameList().then((response) => {
            this.setState({
                parkingSpaceNameList :response.data.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchDeletedTicketList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        deletedTicketList(options).then((response) => {
            this.setState({
                parkTicketList :response.data, 
                loading: false
            });
        })
        .catch((err) => {
            this.state.loading = false;   
            console.log(err);
        })
    }

    //modal
    // tog_scroll = (parkIn) => {
    //     this.setState(prevState => ({
    //       modal_scroll: !prevState.modal_scroll,
    //     }));
    //     if(parkIn.hasOwnProperty('id')){
    //         this.state.parkIn = parkIn;
    //     }
    //     this.removeBodyCss();
    //   }

    tog_scroll = (parkTicktList) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.parkTicktList = parkTicktList;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {          
            this.props.createParkIn(values);
            alert('Created Successfully');
            // this.fetchDeletedTicketList();   
            window.location.reload();    
        }

        handleSearchSubmit(event, values) {       
            // this.state.loading = true;   
            let options = {
                page: this.state.page,
                per_page: this.state.per_page,
                search: this.state.search,
                startDate:values.startDate,
                endDate:values.endDate
            }
            deletedTicketList(options).then((response) => {
                this.setState({
                    parkTicketList :response.data, 
                });
                // this.state.loading = false;
            })
            .catch((err) => {
                console.log(err);
                // this.state.loading = false;
            })

            this.form && this.form.reset();    
        }

       
       handleEditSubmit(event, values) {  
            this.props.restoreParkIn(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
              setTimeout(() =>
                    this.fetchDeletedTicketList(),
                    800
                  );
       }

       handleDeleteSubmit(event, values) {  
        this.props.restoreTicket(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
          }));
          setTimeout(() =>
                this.fetchDeletedTicketList(),
                800
              );
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchDeletedTicketList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchDeletedTicketList();
            });
        }

    render() {
        if (this.state.loading) return <Loader />;

        let vehicleTypeList = [];
        let rateTypeList = [];
        let parkingSpaceNameList = [];
        let parkTicketLists = [];
        let paginator;
      
        if (this.state.parkTicketList.hasOwnProperty('data')) {
            parkTicketLists = this.state.parkTicketList.data;
            paginator = {
                'currentPage':this.state.parkTicketList.current_page,
                'pagesCount':this.state.parkTicketList.last_page,
                'total':this.state.parkTicketList.total,
                'to':this.state.parkTicketList.to,
                'from':this.state.parkTicketList.from,
            }
        }
        const imageLink =process.env.REACT_APP_IMAGE_URL;


        const {id,park_name_en,park_name_bn,f_division_id,f_zilla_id,f_thana_id,address,priority,status,f_device_id,ticket_no,code,ticket_entry_date_ime,ticket_price,is_web} = this.state.parkTicktList;

        let image_url = process.env.REACT_APP_IMAGE_URL ;

        if (this.state.hasOwnProperty('vehicleTypeList')) { vehicleTypeList = this.state.vehicleTypeList; }
        if (this.state.hasOwnProperty('rateTypeList')) { rateTypeList = this.state.rateTypeList; }
        if (this.state.hasOwnProperty('parkingSpaceNameList')) { parkingSpaceNameList = this.state.parkingSpaceNameList; }
        const gateTime = (tran_start_date_time,grace_period) =>{
            let hours = (Math.abs(new Date() -new Date(tran_start_date_time))/1000/60 - grace_period)/60;
            
            if(hours%1 > 0.00 &&  hours%1 < 0.51){
                hours = Math.floor(hours)+1;
            }else{
              hours = Math.floor(hours);
            }
            return hours
        }
        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Deleted Park In List" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="12" md="12">
                        <Card>
                                    <CardBody>
                                    <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleSearchSubmit(e,v) }} ref={c => (this.form = c)}>
                                        <FormGroup row>
                                            <div className="row">
                                            <div className="col-md-4">
                                                {/* <label>Date Range</label> */}
                                                <DatePicker
                                                    selected={this.state.startDate}
                                                    className="form-control" 
                                                    onChange={date => this.setState({startDate: date})}
                                                    selectsStart
                                                    startDate={this.state.startDate}
                                                    // endDate={this.state.endDate}
                                                />
                                                
                                                    <AvField    type="hidden" name="startDate" id="start_date"  value={moment(new Date(this.state.startDate)).format("YYYY-MM-DD")} />
                                                    <AvField    type="hidden" name="endDate" id="end_date" value={moment(new Date(this.state.endDate)).format("YYYY-MM-DD")} />

                                                {/* <AvField className="form-control" label=" Date"  type="date" name="search_date" id="search_date" value={this.state.searchDate} onChange={(e,v)=>this.setState({searchDate: v})} required/> */}
                                            </div>
                                            <div className="col-md-4">
                                            <DatePicker
                                                    selected={this.state.endDate}
                                                    className="form-control" 
                                                    onChange={date => this.setState({endDate: date})}
                                                    selectsEnd
                                                    // startDate={this.state.startDate}
                                                    endDate={this.state.endDate}
                                                    // minDate={this.state.startDate}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                            <Button type="submit" color="success">Search</Button>
                                        </div>
                                            </div>
                                        </FormGroup>
                                        </AvForm>
  
                                    </CardBody>
                                </Card>
                        </Col>
                        <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Deleted Park IN List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <table className="table table-centered table-nowrap mb-0">
                                                <thead className="thead-light">
                                                    <tr>
                                                       <th style={{textAlign:'center'}}>#</th>
                                                        <th style={{textAlign:'center'}}>Ticket Date </th>
                                                        <th style={{textAlign:'center'}}>Token</th>
                                                        <th style={{textAlign:'center'}}>Ticket Type</th>
                                                        <th style={{textAlign:'center'}}>Ticket Price</th>
                                                        <th style={{textAlign:'center'}}>Quantity</th>
                                                        <th style={{textAlign:'center'}}>Amount</th>
                                                        <th style={{textAlign:'center'}}>Is Family</th>
                                                        <th style={{textAlign:'center'}}>Operate By</th>
                                                        <th style={{textAlign:'center'}}>Device</th>
                                                        <th style={{textAlign:'center'}}>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {parkTicketLists&&parkTicketLists.map((parkTicket, index) =>
                                                    <tr key={parkTicket.id .toString()}>
                                                        <td scope="row" style={{textAlign:'center'}}>{index + 1 + ((this.state.page - 1) * this.state.per_page)}</td>
                                                        <td style={{textAlign:'center'}}>{parkTicket.ticket_date}</td>
                                                        <td style={{textAlign:'center'}}>{parkTicket.ticket_no}</td>
                                                        <td style={{textAlign:'center'}}>{parkTicket.park_rate_type != null?parkTicket.park_rate_type.ticket_type:'-'}</td>
                                                        <td style={{textAlign:'center'}}>{parkTicket.ticket_price}</td>
                                                        <td style={{textAlign:'center'}}>1</td>
                                                        <td style={{fontSize:'14px',fontWeight:800,textAlign:'center'}}>{parkTicket.ticket_price}</td>
                                                        <td style={{textAlign:'center'}}>{parkTicket.family_id !== ""?parkTicket.family_id:"-"}</td>
                                                        <td style={{textAlign:'center'}}>{parkTicket.update_user != null?parkTicket.update_user.name:'-'}</td>
                                                        <td style={{textAlign:'center'}}>{<Badge color={(parkTicket.is_web == null || parkTicket.is_web == 0)?'info':'success'}> {(parkTicket.is_web == null || parkTicket.is_web == 0)?'Android':'Web'}</Badge>}</td>
                                                        


                                                       
                                                        
                                                        {/* <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(parkTicketTransactionList) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                        </td> */}

                                                        <td>
                                                            <Button
                                                            onClick={() => { this.tog_scroll(parkTicket) }} 
                                                            data-toggle="modal"
                                                            className="btn btn-sm btn-success">Details</Button>
                                                        </td>

                                                       </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                               
                                {/* detials */}
                                <Modal 
                                className={"modal-dialog-centered"}
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">Ticket  Details</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleDeleteSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Rate Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg="4">
                                                                <div className="text-lg-center">
                                                                    {
                                                                        
                                                                        // vehicle_type.icon_image !== null
                                                                        //     ? <img src={imageLink+'leaseHolderSign/'+park.lease_holder_sign} className="avatar-sm mr-3 rounded-circle mb-4 float-left float-lg-none" alt="img" />
                                                                        //     : <div className="avatar-sm mr-3 mx-lg-auto mb-4 float-left float-lg-none">
                                                                        //         <span className={"avatar-title rounded-circle bg-soft-green text-primary font-size-16"}>
                                                                        //             {/* {vehicle_type.vehicle_type_name_en.charAt(0)} */}
                                                                        //         </span>
                                                                        //       </div>

                                                                         <img src={imageLink+'logo/Group_16436.png'} className="avatar-sm mr-3 rounded-circle mb-4 float-left float-lg-none" alt="img" />
                                                                        
                                                                    }

                                                                    {/* <h5 className="mb-1 font-size-15 text-truncate">{ticket_no}</h5> */}
                                                                    <Badge color={(is_web == null || is_web == 0)?'info':'success'}>Device: {(is_web == null || is_web == 0)?'Android':'Web'}</Badge>
                                                                 

                                                                    
                                                                </div>
                                                            </Col>

                                                            <Col lg="8">
                                                                <div>
                                                                    <Link to="#" className="d-block text-primary mb-2">Code #{code}</Link>
                                                                    <h5 className="text-truncate mb-4 mb-lg-5">{ticket_no}</h5>
                                                                    <ul className="list-inline mb-0">
                                                                        
                                                                        <li className="list-inline-item">
                                                                            <h5 className="font-size-14" id="duedateTooltip"><i className="bx bx-calendar mr-1 text-primary"></i>
                                                                                {moment.unix(ticket_entry_date_ime/1000).format("DD MMM YYYY hh:mm a")}
                                                                                <UncontrolledTooltip placement="top" target="duedateTooltip">
                                                                                   Ticket Booking Date & Time
                                                                                        </UncontrolledTooltip>
                                                                            </h5>
                                                                        </li>
                                                                        <br></br>
                                                                        <br></br>
                                                                        <li className="list-inline-item ">
                                                                            <h5 className="font-size-14" id="amountTooltip">
                                                                                <i className="bx bx-money mr-1 text-primary"></i>
                                                                                                 {ticket_price} Tk.
                                                                                <UncontrolledTooltip placement="top" target="amountTooltip">
                                                                                    Price
                                                                                </UncontrolledTooltip>
                                                                            </h5>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                <AvField  type="hidden" name="id" id="id" value={id}  />

                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Restore</button>

                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>

                            </Col>
                            
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingTransaction;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { restoreTicket })(TicketDeletedList));
