import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import UserProfile from "../pages/Authentication/UserProfile";

import ForgetPwd from "../pages/Authentication/ForgetPassword";

  // Dashboard
import Dashboard from "../pages/Dashboard/index";
import ParkingSpaceNameCreate from "../pages/Settings/ParkingSpace/ParkingSpaceNameCreate";
import ParkingSlot from "../pages/Settings/ParkingSlot/ParkingSlot";
import ParkingRateInfo from "../pages/Settings/ParkingRateInfo/ParkingRateInfo";
import DeviceSettings from "../pages/Settings/DeviceSettings/DeviceSettings";
import Customer from "../pages/Settings/Customer/Customers";
import CustomerVehicleMap from "../pages/Settings/CustomerVehicleMap/CustomerVehicleMapping";
import GenerateBill from "../pages/Settings/Bill/GenerateBill";
import VehicleTypeCreate from "../pages/Settings/VehicleType/VehicleTypeCreate";
//bill
import BillList from "../pages/Settings/Bill/BillList";
import BillDetails from "../pages/Settings/Bill/BillDetails";
import BillPayment from "../pages/Settings/Bill/BillPayment";
//park
import ParkingIn from "../pages/Settings/Park/ParkingIn";
import ParkingOutList from "../pages/Settings/Park/ParkingOutList";
import ParkInDeletedList from "../pages/Settings/Park/ParkInDeletedList";
import ParkOut from "../pages/Settings/Park/ParkOut";
import ParkOutReport from "../pages/Settings/Report/ParkOutReport";
import ParkInReport from "../pages/Settings/Report/ParkInReport";
import ExpenseGroup from "../pages/Settings/ExpenseGroup/ExpenseGroups";
import ExpenseType from "../pages/Settings/ExpenseType/ExpenseTypes";
import ExpenseGroupTypeMap from "../pages/Settings/ExpenseGroupTypeMap/ExpenseGroupTypeMaps";
import ExpenseTransaction from "../pages/Settings/ExpenseTransaction/ExpenseTransaction";
import DayReport from "../pages/Settings/Report/DayReport";
import Role from "../pages/Settings/Role/Roles";
import RoleList from "../pages/Settings/Role/RoleList";
import User from "../pages/Settings/User/Users";
import ViewDetails from "../pages/Settings/ViewDetails/ViewDetails";

//park
import ParkInfoCreate from "../pages/Settings/ParkInfo/ParkInfoCreate";
import ParkRateCreate from "../pages/Settings/ParkRate/ParkRateCreate";
import ParkDeviceAuthorizationCreate from "../pages/Settings/ParkDeviceAuthorization/ParkDeviceAuthorizationCreate";
import ParkTicketTransactions from "../pages/Settings/ParkTicket/ParkTicketTransaction";
import TicketReport from "../pages/TicketReport/TicketReport";
import TicketDeletedList from "../pages/Settings/ParkTicket/TicketDeletedList";
import SummaryReport from "../pages/SummaryReport/SummaryReport";

import MissingList from "../pages/Settings/User/MissingList";








const authProtectedRoutes = [

	{ path: "/dashboard", component: Dashboard },
	{ path: "/profile", component: UserProfile },
	{ path: "/role", component: Role },
	{ path: "/roleList", component: RoleList },
	{ path: "/logout", component: Logout },
	{ path: "/parkingSpaces", component: ParkingSpaceNameCreate },
	{ path: "/parkingSlots", component: ParkingSlot },
	{ path: "/parkingRateInfo", component: ParkingRateInfo },
	{ path: "/deviceSettings", component: DeviceSettings },
	{ path: "/customers", component: Customer },
	{ path: "/customerVehicleMap", component: CustomerVehicleMap },
	{ path: "/user/create", component: User },
	{ path: "/view/details", component: ViewDetails },
	//bill
	{ path: "/bills", component: BillList },
	{ path: "/bill/create", component: GenerateBill },
	{ path: "/bill/details/:id", component: BillDetails },
	{ path: "/bill/payment/:id", component: BillPayment },
	//park
	{ path: "/park/out", component: ParkOut },
	{ path: "/park/in", component: ParkingIn },
	{ path: "/park/list", component: ParkingOutList },
	{ path: "/park/deletedlist", component: ParkInDeletedList },
	//expense
	{ path: "/expenseGroups", component: ExpenseGroup },
	{ path: "/expenseTypes", component: ExpenseType },
	{ path: "/expenseGroupTypeMaps", component: ExpenseGroupTypeMap },
	{ path: "/expensetransaction", component: ExpenseTransaction },

	//report
	{ path: "/report/parkout", component: ParkOutReport },
	{ path: "/report/parkin", component: ParkInReport },
	{ path: "/report/day", component: DayReport },
	

	//park
	{ path: "/parkInfo", component: ParkInfoCreate },
	{ path: "/parkRate", component: ParkRateCreate },
	{ path: "/parkDeviceAuthorization", component: ParkDeviceAuthorizationCreate },
	{ path: "/park-ticket/ticket/transactions", component: ParkTicketTransactions },
	{ path: "/park/ticket/delete/list", component: TicketDeletedList },

	{ path: "/park/report", component: TicketReport},
	{ path: "/combined/summary", component: SummaryReport},

	{ path: "/MissingList", component: MissingList },


	
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/combined/summary" /> },


];

const publicRoutes = [
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },

];

export { authProtectedRoutes, publicRoutes };
