import {CREATE_PARK,EDIT_PARKING, PARKING_SPACE_NAME_ERROR,PARKING_SPACE_NAME_SUCCESS,SHOW_PARKING_SPACE_NAME } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createPark = (parkinfo) => {  
    console.log(parkinfo); 
    return {
        type: CREATE_PARK,
        payload: { parkinfo }
    }
}

export const showParkingSpaceName = (parkingSpaceName_id) => {
    return {
        type: SHOW_PARKING_SPACE_NAME,
        payload: { parkingSpaceName_id }
    }
}

export const editPark = (park_info) => {
    return {
        type: EDIT_PARKING,
        payload: { park_info }
    }
}



export const parkingSpaceNameSuccess = (msg) => {
    return {
        type: PARKING_SPACE_NAME_SUCCESS,
        payload: msg
    }
}


export function parkingSpaceNameShow(parkingSpaceName_id){
    return getApi().get('parkingSpaceName/'+parkingSpaceName_id)
}


export function parkList(params){
    return getApi().get('ParkInfo/list',{
        params: params
    })
}


export const parkingSpaceNameError = (error) => {
    return {
        type: PARKING_SPACE_NAME_ERROR,
        payload: error
    }
}
export function getDivisionDropdown(params) {
    return getApi().get('parkingSpaceName/division/list',{
        params: params
    });
}

export function leaseHolderSignUpload(params,parkInfo_id){
    console.log("image_id",params)
    if(parkInfo_id){
        return getApi().put('ParkInfo/lease/Holder/SignUpload/edit/'+parkInfo_id,params)
    }else{
        return getApi().post('ParkInfo/lease/Holder/SignUpload',params);
    }
}
    
export function getDistrictDropdown(division_id,params) {
    return getApi().get('parkingSpaceName/district/'+division_id,{
        params: params
    });
  }

  export function getThanaDropdown(thana_id,params) {
    return getApi().get('parkingSpaceName/thana/'+thana_id,{
        params: params
    });
}
