import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,Label,FormGroup,Input ,UncontrolledTooltip} from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from "../../../pages/Loader/Loader";
import { Link } from "react-router-dom";
import './ParkTicket.scss';


import { createPark,parkTicketTransactionList,editPark,getDistrictDropdown,getThanaDropdown,getDivisionDropdown,searchParkTicketTransaction,removeTicket} from '../../../store/parkTicketTransaction/actions';

class ParkTicketTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                loading: false,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                startDate: new Date(),
                endDate: new Date(),
                Park: {
                    
                },
                park_Ticket_Transaction_List: []
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    districtHandleChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
         this.fetchDistrict(event.target.value);
        // alert(event.target.value);
        //  this.updateEmployeeObject(name, value);
      }
      
    thanaHandleChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
         this.fetchThana(event.target.value);
        //  this.updateEmployeeObject(name, value);
      }
      
fetchDistrict = (value) => {

  getDistrictDropdown(value)
      .then((response) => {
          this.setState({
              district: response.data
          })
      }, (respose) => {

      })
      .catch((err) => {
          console.log(err);
      })
}


fetchThana = (value) => {

  getThanaDropdown(value)
      .then((response) => {
           console.log("thana",response);
          this.setState({
              thana: response.data
          })
      }, (respose) => {

      })
      .catch((err) => {
          console.log(err);
      })

}

fetchDivision = () => {

    getDivisionDropdown()
        .then((response) => {
             console.log("division",response);
            this.setState({
                division: response.data
            })
        }, (respose) => {
  
        })
        .catch((err) => {
            console.log(err);
        })
  
  }

    componentDidMount()
    {
            this.fetchparkTicketTransactionList();   
            
            // this.fetchDivision();         
    }

    fetchparkTicketTransactionList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search,
            // startDate:moment(this.state.startDate).format("YYYY-MM-DD"),
            // endDate:moment(this.state.endDate).format("YYYY-MM-DD"),
        }
        parkTicketTransactionList(options).then((response) => {
            this.setState({
                park_Ticket_Transaction_List :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    // tog_scroll = (Park) => {
    //     this.setState(prevState => ({
    //       modal_scroll: !prevState.modal_scroll,
    //     }));
    //     this.state.Park = Park;
    //     this.fetchDistrict(Park.f_division_id);
    //     this.fetchThana(Park.f_zilla_id);
    //     this.removeBodyCss();
    //   }

      tog_scroll = (Park) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.Park = Park;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }


      autoSearchInputChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
        // this.updateDesignationObject(name, value);
      }
      
      // handleValidSubmit
        handleValidSubmit(event, values) {       
            // this.state.loading = true;   
            let options = {
                page: this.state.page,
                per_page: this.state.per_page,
                search: this.state.search,
                startDate:values.startDate,
                endDate:values.endDate
            }
            parkTicketTransactionList(options).then((response) => {
                this.setState({
                    park_Ticket_Transaction_List :response.data, 
                });
                // this.state.loading = false;
            })
            .catch((err) => {
                console.log(err);
                // this.state.loading = false;
            })

            // this.form && this.form.reset();    
        }
       
       handleEditSubmit(event, values) {  
            this.props.editPark(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
             this.fetchparkTicketTransactionList();  
       }

       //delet
       handleDeleteSubmit(event, values) {  
        var x = window.confirm('Are you sure to delete?');
        if(x == true){
             this.props.removeTicket(values);
             this.setState(prevState => ({
                 modal_scroll: !prevState.modal_scroll,
             }));
             setTimeout(() =>
                 this.fetchparkTicketTransactionList(),
                 800
               );
        }
    }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchparkTicketTransactionList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchparkTicketTransactionList();
            });
        }

    render() {
        if (this.state.loading) return <Loader />;
        let divisionList = [];
        let districtList = [];
        let thanaList = [];

        if (this.state.hasOwnProperty('division')) {
            divisionList =  this.state.division;
          }

        if (this.state.hasOwnProperty('district')) {
            districtList =  this.state.district;
          }

        if (this.state.hasOwnProperty('thana')) {
            thanaList =  this.state.thana;
          }
        let parkTicketTransactionLists = [];
        let paginator;
        if (this.state.hasOwnProperty('park_Ticket_Transaction_List')) {
            parkTicketTransactionLists = this.state.park_Ticket_Transaction_List.data;
            paginator = {
                'currentPage': this.state.park_Ticket_Transaction_List.current_page,
                'pagesCount': this.state.park_Ticket_Transaction_List.last_page,
                'total': this.state.park_Ticket_Transaction_List.total,
                'to': this.state.park_Ticket_Transaction_List.to,
                'from': this.state.park_Ticket_Transaction_List.from,
                'startDate': this.state.park_Ticket_Transaction_List.startDate,
                'endDate': this.state.park_Ticket_Transaction_List.endDate,
            }
        }

        const imageLink =process.env.REACT_APP_IMAGE_URL;

        const {id,park_name_en,park_name_bn,f_division_id,f_zilla_id,f_thana_id,address,priority,status,f_device_id,ticket_no,code,ticket_entry_date_ime,ticket_price,is_web} = this.state.Park;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Park Ticket Transactions" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="12" md="12">
                        <Card>
                                    <CardBody>
                                    <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                        <FormGroup row>
                                            <div className="row">
                                            <div className="col-md-4">
                                                {/* <label>Date Range</label> */}
                                                <DatePicker
                                                    selected={this.state.startDate}
                                                    className="form-control" 
                                                    onChange={date => this.setState({startDate: date})}
                                                    selectsStart
                                                    startDate={this.state.startDate}
                                                    // endDate={this.state.endDate}
                                                />
                                                
                                                    <AvField    type="hidden" name="startDate" id="start_date"  value={moment(new Date(this.state.startDate)).format("YYYY-MM-DD")} />
                                                    <AvField    type="hidden" name="endDate" id="end_date" value={moment(new Date(this.state.endDate)).format("YYYY-MM-DD")} />

                                                {/* <AvField className="form-control" label=" Date"  type="date" name="search_date" id="search_date" value={this.state.searchDate} onChange={(e,v)=>this.setState({searchDate: v})} required/> */}
                                            </div>
                                            <div className="col-md-4">
                                            <DatePicker
                                                    selected={this.state.endDate}
                                                    className="form-control" 
                                                    onChange={date => this.setState({endDate: date})}
                                                    selectsEnd
                                                    // startDate={this.state.startDate}
                                                    endDate={this.state.endDate}
                                                    // minDate={this.state.startDate}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                            <Button type="submit" color="success">Search</Button>
                                        </div>
                                            </div>
                                        </FormGroup>
                                        </AvForm>
  
                                    </CardBody>
                                </Card>
                        </Col>
                        <Col lg="12" md="12">
                                <Card className="waterMark">
                                    <CardBody>
                                        <CardTitle>Park Ticket Transactions List</CardTitle>
                                        <Link to="/park/ticket/delete/list" style={{marginLeft: '76%', fontSize: '20px'}}> Deleted List</Link>

                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>

                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th style={{textAlign:'center'}}>#</th>
                                                        <th style={{textAlign:'center'}}>Date & Time</th>
                                                        <th style={{textAlign:'center'}}>Token</th>
                                                        <th style={{textAlign:'center'}}>Ticket Type</th>
                                                        <th style={{textAlign:'center'}}>Ticket Price</th>
                                                        <th style={{textAlign:'center'}}>Quantity</th>
                                                        <th style={{textAlign:'center'}}>Amount</th>
                                                        <th style={{textAlign:'center'}}>Is Family</th>
                                                        <th style={{textAlign:'center'}}>Operate By</th>
                                                        <th style={{textAlign:'center'}}>Device</th>
                                                        <th style={{textAlign:'center'}}>Status</th>
                                                        {/* <th>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {parkTicketTransactionLists&&parkTicketTransactionLists.map((parkTicketTransactionList, index) =>
                                                    <tr key={parkTicketTransactionList.id .toString()}>
                                                        <td scope="row" style={{textAlign:'center'}}>{index + 1 + ((this.state.page - 1) * this.state.per_page)}</td>
                                                        <td style={{textAlign:'center'}}>{ moment.unix(parkTicketTransactionList.ticket_entry_date_ime/1000).format("DD MMM YYYY hh:mm a")}</td>
                                                        <td style={{textAlign:'center'}}>{parkTicketTransactionList.ticket_no}</td>
                                                        <td style={{textAlign:'center'}}>{parkTicketTransactionList.park_rate_type != null?parkTicketTransactionList.park_rate_type.ticket_type:'-'}</td>
                                                        <td style={{textAlign:'center'}}>{parkTicketTransactionList.ticket_price}</td>
                                                        <td style={{textAlign:'center'}}>1</td>
                                                        <td style={{fontSize:'14px',fontWeight:800,textAlign:'center'}}>{parkTicketTransactionList.ticket_price}</td>
                                                        <td style={{textAlign:'center'}}>{parkTicketTransactionList.family_id !== ""?parkTicketTransactionList.family_id:"-"}</td>
                                                        <td style={{textAlign:'center'}}>{parkTicketTransactionList.insert_user != null?parkTicketTransactionList.insert_user.name:'-'}</td>
                                                        <td style={{textAlign:'center'}}>{<Badge style={{backgroundColor:'#4e949b'}}> {parkTicketTransactionList.api_device_name}</Badge>}</td>
                                                        


                                                       
                                                        
                                                        {/* <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(parkTicketTransactionList) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                        </td> */}

                                                        <td>
                                                            <Button
                                                            onClick={() => { this.tog_scroll(parkTicketTransactionList) }} 
                                                            data-toggle="modal"
                                                            className="btn btn-sm btn-success">Details</Button>
                                                        </td>

                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>


                                {/* detials */}
                                <Modal 
                                className={"modal-dialog-centered"}
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">Ticket  Details</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleDeleteSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Rate Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg="4">
                                                                <div className="text-lg-center">
                                                                    {
                                                                        
                                                                        // vehicle_type.icon_image !== null
                                                                        //     ? <img src={imageLink+'leaseHolderSign/'+park.lease_holder_sign} className="avatar-sm mr-3 rounded-circle mb-4 float-left float-lg-none" alt="img" />
                                                                        //     : <div className="avatar-sm mr-3 mx-lg-auto mb-4 float-left float-lg-none">
                                                                        //         <span className={"avatar-title rounded-circle bg-soft-green text-primary font-size-16"}>
                                                                        //             {/* {vehicle_type.vehicle_type_name_en.charAt(0)} */}
                                                                        //         </span>
                                                                        //       </div>

                                                                         <img src={imageLink+'logo/Group_16436.png'} className="avatar-sm mr-3 rounded-circle mb-4 float-left float-lg-none" alt="img" />
                                                                        
                                                                    }

                                                                    {/* <h5 className="mb-1 font-size-15 text-truncate">{ticket_no}</h5> */}
                                                                    <Badge color={(is_web == null || is_web == 0)?'info':'success'}>Device: {(is_web == null || is_web == 0)?'Android':'Web'}</Badge>
                                                                 

                                                                    
                                                                </div>
                                                            </Col>

                                                            <Col lg="8">
                                                                <div>
                                                                    <Link to="#" className="d-block text-primary mb-2">Code #{code}</Link>
                                                                    <h5 className="text-truncate mb-4 mb-lg-5">{ticket_no}</h5>
                                                                    <ul className="list-inline mb-0">
                                                                        
                                                                        <li className="list-inline-item">
                                                                            <h5 className="font-size-14" id="duedateTooltip"><i className="bx bx-calendar mr-1 text-primary"></i>
                                                                            {moment.unix(ticket_entry_date_ime/1000).format("DD MMM YYYY hh:mm a")}
                                                                                <UncontrolledTooltip placement="top" target="duedateTooltip">
                                                                                   Ticket Booking Date & Time
                                                                                        </UncontrolledTooltip>
                                                                            </h5>
                                                                        </li>
                                                                        <br></br>
                                                                        <br></br>
                                                                        <li className="list-inline-item ">
                                                                            <h5 className="font-size-14" id="amountTooltip">
                                                                                <i className="bx bx-money mr-1 text-primary"></i>
                                                                                                 {ticket_price} Tk.
                                                                                <UncontrolledTooltip placement="top" target="amountTooltip">
                                                                                    Price
                                                                                </UncontrolledTooltip>
                                                                            </h5>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                <AvField  type="hidden" name="id" id="id" value={id}  />

                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-danger save-event">Delete</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>

                            </Col>
                            
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingSpaceName;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { removeTicket })(ParkTicketTransaction));
