import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_PARK,PARKING_SPACE_NAME_SUCCESS,EDIT_PARKING,SHOW_PARKING_SPACE_NAME,REMOVE_TICKET,RESTORE_TICKET } from './actionTypes';
import { parkingSpaceNameSuccess, parkingSpaceNameError,parkingSpaceNameShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData ,deleteJwtData } from '../../helpers/self_accounts_settings_helper';

function* createPark({ payload: { parkinfo } }) {

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'ParkInfo/create', parkinfo);
               
             if(response.success == false){
                
                yield put(parkingSpaceNameError(response.message));
            }else if(response.success == true){
                
                yield put(parkingSpaceNameSuccess(response.data.message));
            }
          }
    } catch (error) {
        yield put(parkingSpaceNameError(error));
    }
}


function* editPark({ payload: { park_info } }) {
   
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'ParkInfo/'+park_info.id, park_info);
             if(response.success == false){
                
                yield put(parkingSpaceNameError(response.message));
            }else if(response.success == true){
                
                yield put(parkingSpaceNameSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(parkingSpaceNameError(error));
    }
}


function* showParkingSpaceName({ payload: { parkingSpaceName_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'parkingSpaceName/'+parkingSpaceName_id);
             yield put(parkingSpaceNameShow(response));
          }
          
          
    } catch (error) {
        yield put(parkingSpaceNameError(error));
    }
}

function* removeTicket({ payload: { ticket } }) { console.log("Ticket",ticket)
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(deleteJwtData, 'parkTicketTransaction/'+ticket.id);
             if(response.success == false){
                
                yield put(parkingSpaceNameError(response.message));
            }else if(response.success == true){
                
                yield put(parkingSpaceNameSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(parkingSpaceNameError(error));
    }
}

function* restoreTicket({ payload: { restoreTicketID } }) { console.log(restoreTicketID)
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'parkTicketTransaction/restore/'+restoreTicketID.id);
             if(response.success == false){
                
                yield put(parkingSpaceNameError(response.message));
            }else if(response.success == true){
                
                yield put(parkingSpaceNameSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(parkingSpaceNameError(error));
    }
}

export function* watchPark() {
    yield takeEvery(CREATE_PARK, createPark)
     yield takeEvery(EDIT_PARKING, editPark)
    // yield takeEvery(SHOW_PARKING_SPACE_NAME, showParkingSpaceName)
     yield takeEvery(REMOVE_TICKET, removeTicket)
     yield takeEvery(RESTORE_TICKET, restoreTicket)


}

function* parkTicketTransactionSaga() {
    yield all([
        fork(watchPark),
    ]);
}

export default parkTicketTransactionSaga;