import React, { Component } from "react";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter, Media, Table } from "reactstrap";
import { Link } from "react-router-dom";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import { dashboardDataShow} from '../../store/dashboard/actions';


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: [
                { title: "Vehicle", iconClass: "bx-copy-alt", description: "1,235" },
                { title: "Bill", iconClass: "bx-archive-in", description: "৳35, 723" },
                { title: "Average Price", iconClass: "bx-purchase-tag-alt", description: "৳62" }
            ],
            email: [
                { title: "Week", linkto: "#", isActive: false },
                { title: "Month", linkto: "#", isActive: false },
                { title: "Year", linkto: "#", isActive: true }
            ],
            modal: false
        };
        this.togglemodal.bind(this);
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    fetchDashboardList = () => {
        let serviceId = {
            serviceId:0
        };
        if (localStorage.getItem('authService') != null) {

            let service = localStorage.getItem('authService') * 1;
            serviceId = {
                serviceId:service
            };
          }
        dashboardDataShow(serviceId).then((response) => {
            this.setState({
                dashboardData :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }
    componentDidMount()
    {
    
            this.fetchDashboardList();     
    }
    render() {
        let dashboardDataE = [];
        let monthWiseIncome = [];
        let monthWiseExpense = [];
        let monthlyIncome = [];
        let today_parkTicket_sale = [];
        let today_Expense = [];

        if (this.state.hasOwnProperty('dashboardData')) {
            dashboardDataE = this.state.dashboardData;
            monthWiseIncome = this.state.dashboardData.monthWiseIncome;
            monthWiseExpense = this.state.dashboardData.monthWiseExpense;
            monthlyIncome = this.state.dashboardData.monthly_income;
            today_parkTicket_sale = this.state.dashboardData.today_parkTicket_sale;
            today_Expense = this.state.dashboardData.today_Expense;
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumbs title={this.props.t('Dashboard')} breadcrumbItem={this.props.t('Dashboard')} />

                        <Row>
                            <Col xl="4">
                                <WelcomeComp dashboardDataE = {dashboardDataE}/>
                                <MonthlyEarning monthly_income = {monthlyIncome}/>
                            </Col>
                            <Col xl="8">
                                <Row>
                                    {/* Reports Render */}
                                            <Col md="4" >
                                                <Card className="mini-stats-wid">
                                                    <CardBody>
                                                        <Media>
                                                            <Media body>
                                                                <p className="text-muted font-weight-medium">Today Income</p>
                                                                <h4 className="mb-0">{dashboardDataE.today_income} Tk.</h4>
                                                            </Media>
                                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                                <span className="avatar-title">
                                                                    <i className={"fa fa-money-bill-alt font-size-24"}></i>
                                                                </span>
                                                            </div>
                                                        </Media>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md="4" >
                                                <Card className="mini-stats-wid">
                                                    <CardBody>
                                                        <Media>
                                                            <Media body>
                                                                <p className="text-muted font-weight-medium">Today ParkTicket Sale Qty</p>
                                                                <h4 className="mb-0">{dashboardDataE.today_parkTicket_sale}</h4>
                                                            </Media>
                                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                               {/* <Link to="/report/parkin"> */}
                                                                <span className="avatar-title"> 
                                                                    <i className={"fas fa-taxi font-size-24"}></i>
                                                                </span>
                                                                {/* </Link> */}
                                                            </div>
                                                        </Media>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md="4" >
                                                <Card className="mini-stats-wid">
                                                    <CardBody>
                                                        <Media>
                                                            <Media body>
                                                                <p className="text-muted font-weight-medium">Today Expense</p>
                                                                <h4 className="mb-0">{dashboardDataE.today_Expense}</h4>
                                                            </Media>
                                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                               {/* <Link to="report/parkout"> */}
                                                                 <span className="avatar-title">
                                                                    <i className={"fab fa-creative-commons-pd font-size-24"}></i>
                                                                </span>
                                                                {/* </Link> */}
                                                            </div>
                                                        </Media>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                </Row>

                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4 float-sm-left">
                                            Parking Income Vs Expense
                                        </CardTitle>
                                        <div className="float-sm-right">
                                            <ul className="nav nav-pills">
                                                {
                                                    this.state.email.map((mail, key) =>
                                                        <li className="nav-item" key={"_li_" + key}>
                                                            <Link className={mail.isActive ? "nav-link active" : "nav-link"} to={mail.linkto}>{mail.title}</Link>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        <div className="clearfix"></div>
                                        <StackedColumnChart monthWiseIncomeChart ={monthWiseIncome} monthWiseExpenseChart ={monthWiseExpense} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
                <Modal isOpen={this.state.modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={this.togglemodal}>
                    <div className="modal-content">
                        <ModalHeader toggle={this.togglemodal}>
                            Order Details
                        </ModalHeader >
                        <ModalBody>
                            <p className="mb-2">Product id: <span className="text-primary">#SK2540</span></p>
                            <p className="mb-4">Billing Name: <span className="text-primary">Neal Matthews</span></p>

                            <div className="table-responsive">
                                <Table className="table table-centered table-nowrap">
                                    <thead>
                                        <tr>
                                            <th scope="col">Product</th>
                                            <th scope="col">Product Name</th>
                                            <th scope="col">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">
                                                <div>
                                                    <img src={modalimage1} alt="" className="avatar-sm" />
                                                </div>
                                            </th>
                                            <td>
                                                <div>
                                                    <h5 className="text-truncate font-size-14">Wireless Headphone (Black)</h5>
                                                    <p className="text-muted mb-0">৳ 225 x 1</p>
                                                </div>
                                            </td>
                                            <td>৳ 255</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <div>
                                                    <img src={modalimage2} alt="" className="avatar-sm" />
                                                </div>
                                            </th>
                                            <td>
                                                <div>
                                                    <h5 className="text-truncate font-size-14">Hoodie (Blue)</h5>
                                                    <p className="text-muted mb-0">৳ 145 x 1</p>
                                                </div>
                                            </td>
                                            <td>৳ 145</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <h6 className="m-0 text-right">Sub Total:</h6>
                                            </td>
                                            <td>৳ 400</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <h6 className="m-0 text-right">Shipping:</h6>
                                            </td>
                                            <td>Free</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <h6 className="m-0 text-right">Total:</h6>
                                            </td>
                                            <td>৳ 400</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" onClick={this.togglemodal}>Close</Button>
                        </ModalFooter>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Dashboard);
