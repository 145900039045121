import React, { Component } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/logo-sm-light.png";
import logoLight from "../../assets/images/logo-sm-light.png";
import logoLightSvg from "../../assets/images/logo-sm-light.png";
import logoDark from "../../assets/images/logo-sm-light.png";

// import images
import expense from "../../assets/images/webLogo/expense.png";
import parking from "../../assets/images/webLogo/parking.png";
import service from "../../assets/images/webLogo/service.png";
import report from "../../assets/images/webLogo/report.png";
import ticket from "../../assets/images/webLogo/ticket.png";
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

// Redux Store
import { accountInfo, toggleRightSidebar } from "../../store/actions";

//i18n
import { withNamespaces } from 'react-i18next';
import { serviceList } from "../../store/service/actions";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { isSearch: false,active: null };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  componentDidMount() {
    this.fetchAccountInfo();
    this.fetchService();
    if (localStorage.getItem('authService') != null) {
      localStorage.setItem("authService", JSON.stringify(""));
    }
  }

  fetchAccountInfo = () => {
    accountInfo().then(response => {
      this.setState({
        accountInfo: response.data
      })
    })
  }
  fetchService = () => {
    serviceList().then(response => {
      this.setState({
        service: response.data
      })
    })
  }
  serviceToggle(id) {
    localStorage.setItem("authService", JSON.stringify(id));
    this.setState({ active: id })

  }

  activeService(id) {
    if (this.state.active === id) {
      return "#E2E2E2";
    }
    return "";
  }

  render() {

    let accountInformation = [];
    let serviceList = [];
    if (this.state.hasOwnProperty('accountInfo')) {
      accountInformation = this.state.accountInfo.park_account_info[0];
    }
    if (this.state.hasOwnProperty('service')) {
      serviceList = this.state.service.registeredServiceList;
    }
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="60" />
                  </span>
                </Link>

                <Link to="/dashboard" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="22" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content">
                <i className="fa fa-fw fa-bars"></i>
              </button>

            </div>
            <div className="d-flex justify-content-center">
              <div className="d-none d-lg-block font-weight-bold " style={{ fontSize: '32px' }} >
                <div className="position-relative">
                  {accountInformation && accountInformation.park_name_en}
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ml-2">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                  onClick={() => { this.setState({ isSearch: !this.state.isSearch }); }}>
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div
                  className={this.state.isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={this.props.t('Search') + "..."}
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              {/* <LanguageDropdown /> */}

              <Dropdown className="d-none d-inline-block ml-1" isOpen={this.state.socialDrp} toggle={() => { this.setState({ socialDrp: !this.state.socialDrp }) }}>
                <DropdownToggle className="btn header-item noti-icon waves-effect" caret tag="button">
                  <i className="bx bx-customize"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg" right>
                  <div className="px-lg-2">
                  <Row className="no-gutters">
                      {serviceList && serviceList.map((service, index) =>
                        <Col key={index} className="px-1">
                          <a className="dropdown-icon-item" href={service.url} style={{ backgroundColor: this.activeService(service.id) }} onClick={() => this.serviceToggle(service.id)}>
                          <img src={service.service_name_en == "Park Ticket" ? ticket : service.service_name_en == "Parking" ? parking : service.service_name_en == "Expense"?expense:''} alt="ticket" className="w-50 h-50" />
                            <span>{service.service_name_en}</span>
                          </a>
                        </Col>
                      )}
                      <Col>
                        <a className="dropdown-icon-item" href="#" style={{ cursor: "default", pointerEvents: 'none', backgroundColor: '#E2E2E2' }}>
                          <img src={report} alt="report" className="w-50 h-50" />
                          <span>Report</span>
                        </a>
                      </Col>
                      {/* <Col >
                        <Link className="dropdown-icon-item" to="#" >
                          <img src={ticket} alt="ticket" className="w-50 h-50" />
                          <span>Ticket</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={parking} alt="parking" className="w-50 h-50" />
                          <span>Parking</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#" style={{ cursor: "default", pointerEvents: 'none', backgroundColor: '#E2E2E2' }}>
                          <img src={expense} alt="expense" className="w-50 h-50" />
                          <span>Expense</span>
                        </Link>
                      </Col> */}

                    </Row>
                    {/* <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={service} alt="service" className="w-50 h-50" />
                          <span>Service</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#" style={{ cursor: "default", pointerEvents: 'none', backgroundColor: '#E2E2E2' }}>
                          <img src={report} alt="report" className="w-50 h-50" />
                          <span>Report</span>
                        </Link>
                      </Col>
                      <Col>

                      </Col>
                    </Row> */}
                  </div>
                </DropdownMenu>
              </Dropdown>

              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>

              <NotificationDropdown />

              <ProfileMenu />

              {/* <div className="dropdown d-inline-block">
                <button
                  onClick={this.toggleRightbar}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle waves-effect" >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> */}
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withNamespaces()(Header));
