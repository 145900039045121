import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,Label,FormGroup,Input } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createParkRate,parkRateList,editParkRate,getDistrictDropdown,getThanaDropdown,getParkInfoDropdown} from '../../../store/parkRate/actions';

class ParkRateCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                ParkRate: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    districtHandleChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
         this.fetchDistrict(event.target.value);
        // alert(event.target.value);
        //  this.updateEmployeeObject(name, value);
      }
      
    thanaHandleChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
         this.fetchThana(event.target.value);
        //  this.updateEmployeeObject(name, value);
      }
      
fetchDistrict = (value) => {

  getDistrictDropdown(value)
      .then((response) => {
          this.setState({
              district: response.data
          })
      }, (respose) => {

      })
      .catch((err) => {
          console.log(err);
      })
}


fetchThana = (value) => {

  getThanaDropdown(value)
      .then((response) => {
           
          this.setState({
              thana: response.data
          })
      }, (respose) => {

      })
      .catch((err) => {
          console.log(err);
      })

}

fetchParkInfo = () => {

    getParkInfoDropdown()
        .then((response) => {
            this.setState({
                parkInfo: response.data.data
            })
        }, (respose) => {
  
        })
        .catch((err) => {
            console.log(err);
        })
  
  }

    componentDidMount()
    {
            this.fetchparkRateList();   
            
            this.fetchParkInfo();         
    }

    fetchparkRateList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        parkRateList(options).then((response) => {
            this.setState({
                parkRateList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (ParkRate) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.ParkRate = ParkRate;
        // this.fetchDistrict(Park.f_division_id);
        // this.fetchThana(Park.f_zilla_id);
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }


      autoSearchInputChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
        // this.updateDesignationObject(name, value);
      }
      
      // handleValidSubmit
        handleValidSubmit(event, values) {       
            this.props.createParkRate(values);

            this.form && this.form.reset();
            this.fetchparkRateList();    
        }
       
       handleEditSubmit(event, values) {  
            this.props.editParkRate(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
             this.fetchparkRateList();  
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchparkRateList();
        });
       }

    //    ..

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchparkRateList();
            });
        }

    render() {
        let parkInfoList = [];
        let districtList = [];
        let thanaList = [];

        if (this.state.hasOwnProperty('parkInfo')) {
            parkInfoList =  this.state.parkInfo;
          }

        if (this.state.hasOwnProperty('district')) {
            districtList =  this.state.district;
          }

        if (this.state.hasOwnProperty('thana')) {
            thanaList =  this.state.thana;
          }
        let parkRateList = [];
        let paginator;
        if (this.state.hasOwnProperty('parkRateList')) {
            parkRateList = this.state.parkRateList.data;
            paginator = {
                'currentPage':this.state.parkRateList.currentPage,
                'pagesCount':this.state.parkRateList.pagesCount,
                'total':this.state.parkRateList.total,
                'to':this.state.parkRateList.to,
                'from':this.state.parkRateList.from,
            }
        }
        const {id,f_park_info_id,ticket_type,rate,priority,status} = this.state.ParkRate;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Park Ticket" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Park Ticket </CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                               
                                                <div className="form-group  col-md-12 ">
                                                        <AvField type="select"className="custom-select form-control" label="Select Park" name="f_park_info_id" id="f_park_info_id" onChange={(e) => this.districtHandleChange(e)} required>
                                                                <option defaultValue>Select Park</option>
                                                                {parkInfoList.map((Park, index) =>
                                                                    <option  value={Park.id}>{Park.park_name_en}</option>
                                                                )}
                                                        </AvField>
                                                </div>
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Ticket Type"  id="ticket_type" name="ticket_type"  required/>
                                                     {/* <AvField name="id" id="id" value={id}  type="hidden"  /> */}
                                                </div>  
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Ticket Rate"  id="rate" name="rate"  required/>
                                                     {/* <AvField name="id" id="id" value={id}  type="hidden"  /> */}
                                                </div>

                                                <div className="form-group col-md-12 " >
                                                        <AvField type="select" className="custom-select form-control" label="Status" name="status" id="Status" onChange={(e) => this.autoSearchInputChange(e)}  required>
                                                            <option defaultValue>Select Status</option>
                                                            <option value="1">active</option>
                                                            <option value="2">Deactive</option>
                                                        </AvField>
                                                </div>

                                                <div className="col-md-12 ">
                                                         <AvField className="form-control" type="text" label="Priority"  id="priority" name="priority"  required/>
                                                </div>  

                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                        </Col>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Park Ticket List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Park Name</th>
                                                        <th>Ticket Type</th>
                                                        <th>Rate</th>
                                                        <th>Status</th>
                                                        <th>Priority</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {parkRateList.map((parkRate, index) =>
                                                    <tr key={parkRate.id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{parkRate.park_info.park_name_en}</td>
                                                        <td>{parkRate.ticket_type}</td>
                                                        <td>{parkRate.rate}</td>
                                                        <td>{parkRate.status == 1?"Active":"Deactive"}</td>
                                                        <td>{parkRate.priority}</td>

                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(parkRate) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       {/* <h5 className="modal-title mt-0">{id}</h5> */}
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>

                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Park Rate Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                <div className="form-group  col-md-12 ">
                                                        <AvField type="select"className="custom-select form-control" label="Select Park" name="f_park_info_id" id="f_park_info_id"  value={f_park_info_id}onChange={(e) => this.districtHandleChange(e)}  required>
                                                                <option value="">Select Park</option>
                                                                {parkInfoList.map((Park, index) =>
                                                                    <option  value={Park.id}>{Park.park_name_en}</option>
                                                                )}
                                                        </AvField>
                                                </div>
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Ticket Type"  id="ticket_type" name="ticket_type" value={ticket_type}  required/>
                                                </div>

                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Ticket Rate"  id="rate" name="rate" value={rate}  required/>
                                                     <AvField name="id" id="id" value={id}  type="hidden"  />
                                                </div>

                                                <div className="form-group col-md-12 " >
                                                        <AvField type="select" className="custom-select form-control" label="Status" name="status" id="Status" value={status} onChange={(e) => this.autoSearchInputChange(e)}  required>
                                                            <option value="">Select Status</option>
                                                            <option value="1">active</option>
                                                            <option value="2">Deactive</option>
                                                        </AvField>
                                                </div>

                                                <div className="col-md-12 ">
                                                         <AvField className="form-control" type="text" label="Priority"  id="priority" name="priority" value={priority}  required/>
                                                </div> 

                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingSpaceName;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createParkRate,editParkRate })(ParkRateCreate));
