import React, { Component } from "react";
import {
    Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardHeader, CardFooter, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, FormGroup
} from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
// import {CustomPagination} from "./Common/CustomPagination";
// import {DataTabeSearch} from './Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { createParkIn, searchByParkOutReport, sendSms, specialETicket,MissingBalance, summaryParking, summaryTicket, ticketFistLoad, ticketReport } from '../../store/park/actions';
import { rateTypeList, vehicleTypeList } from '../../store/parkingRate/actions';
import { companyInfo } from '../../store/bill/actions';
import './summary.scss';
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../pages/Loader/Loader";
import { accountInfo, parkingAccountInfo } from "../../store/actions";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';
import logo from "../../assets/images/logo-light.png";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import '../../assets/fonts/Nikosh-normal.js';




class SummaryReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            loading: false,
            toggleSwitch: false,
            // startDate: new Date().toISOString().split('T')[0],
            startDate: new Date(),
            endDate: new Date(),
            edittoggleSwitch: false,
            modal_1: false,
            modal_scroll: false,
            autoNumber: "",
            slot: {

            }
        };


        // this.tog_scroll = this.tog_scroll.bind(this);

    }





    componentDidMount() {
        this.fetchRateTypeList();
        this.fetchAccountInfo();
        this.fetchVehicleTypeList();
        this.fetchCompanyInfo();
        this.fetchParkingAccountInfo();

        let startDate = moment(new Date()).format("YYYY-MM-DD");
        let endDate = moment(new Date()).format("YYYY-MM-DD");
        let ticketData = {
            startDate: startDate,
            endDate: endDate,
        }
        let parkingData = {
            start_date: startDate,
            end_date: endDate,
        }


        summaryTicket(ticketData).then((response) => {
            this.setState({
                totalTicket: response.data,
                ticketSummary: response.data,
                loading: false,
            });
        })
        specialETicket(ticketData).then((response) => {
            this.setState({
                specialETicket: response.data,
                loading: false,
            });
        })
        MissingBalance(ticketData).then((response) => {
            this.setState({
                MissingBalance: response.data,
                loading: false,
            });
        })
        summaryParking(parkingData).then((response) => {
            this.setState({
                parkingSummary: response.data.parkOut,
                expenseSummary: response.data.expense,
                expense: response.data,
                expenseOthers: response.data.individualExpense,
                loading: false,
            });
            // console.log(this.state.parkingSummary);
        })

    }
    fetchAccountInfo = () => {
        accountInfo().then(response => {
            this.setState({
                accountInfo: response.data
            })
        })
    }
    fetchParkingAccountInfo = () => {
        parkingAccountInfo().then(response => {
            this.setState({
                parkingAccountInfo: response.data
            })
        })
    }

    fetchRateTypeList = () => {
        rateTypeList().then((response) => {
            this.setState({
                rateTypeList: response.data.rateType,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchVehicleTypeList = () => {
        vehicleTypeList().then((response) => {
            this.setState({
                vehicleTypeList: response.data.vehicleType,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (slot) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.slot = slot;
        this.removeBodyCss();
    }

    // background-color: rgba(255, 255, 255, 0.9) !important; 
    printDiv = (e, startDate, endDate) => {
        e.preventDefault();
        var content = document.getElementById('ForPrint').innerHTML;
        var title = document.title + "-" + moment(new Date(startDate)).format("DD-MM-YYYY") + "-to-" + moment(new Date(endDate)).format("DD-MM-YYYY");
        var printWindow = window.open("", "_blank", "width=800,height=600");
        printWindow.document.open();
        printWindow.document.write('<html><head><title>' + title + '</title><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous"><style>footer { font-size: 10px; text-align: center;} @media print { footer { position: fixed; bottom: 0;} .content-block, p { page-break-inside: avoid;} html, body { width: 210mm; height: 297mm; }.table-bordered th, .table-bordered td {height:40px;border:1px solid #000000 !important; -webkit-print-color-adjust:exact;} .table-report th, td {height:40px;padding:0.25rem !important;border:1px solid #000000 !important; -webkit-print-color-adjust:exact;} .bg-text img{ position:absolute !important;z-index:4, height:350px !important; width:350px !important; opacity:0.3; top:15% ; right:0; left:35%; bottom:0} .bg-text{ font-weight: bold !important;  position: absolute!important; top: 50%!important; left: 50%!important; transform: translate(-50%, -50%)!important;z-index: 2!important;width: 100%!important; padding: 150px!important; text-align: center!important; -webkit-print-color-adjust:exact!important; }}</style></head><body>');
        printWindow.document.write(content);
        printWindow.document.write('<footer>Printed at: ' + new Date().toLocaleString() + '</footer></body></html>');
        printWindow.document.close();
        printWindow.focus();
        setTimeout(function () {
            printWindow.print();
            printWindow.close();
        }, 1000);
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.state.loading = true;
        this.setState({
            startDate: values.start_date,
            endDate: values.end_date,
        })
        let startDate = values && moment(new Date(values.start_date)).format("YYYY-MM-DD");
        let endDate = values && moment(new Date(values.end_date)).format("YYYY-MM-DD");
        let ticketData = {
            startDate: startDate,
            endDate: endDate,
        }
        let parkingData = {
            start_date: startDate,
            end_date: endDate,
        }


        summaryTicket(ticketData).then((response) => {
            this.setState({
                totalTicket: response.data,
                ticketSummary: response.data,
                loading: false,
            });
        })

        specialETicket(ticketData).then((response) => {
            this.setState({
                specialETicket: response.data,
                loading: false,
            });
        })

        MissingBalance(ticketData).then((response) => {
            this.setState({
                MissingBalance: response.data,
                loading: false,
            });
        })
        


        summaryParking(parkingData).then((response) => {
            this.setState({
                parkingSummary: response.data.parkOut,
                expenseSummary: response.data.expense,
                expense: response.data,
                expenseOthers: response.data.individualExpense,
                loading: false,
            });
            // console.log(this.state.parkingSummary);
        })

            .catch((err) => {
                this.state.loading = false;
                console.log(err);
            })
        // this.form && this.form.reset();

    }

    handleSendSMS(event, values) {

        if (values.mobile_number == "" || values.mobile_number == null || values.mobile_number == undefined) {
            toastr.error('Please write mobile number');
        }

        if (values.mobile_number != "" || values.mobile_number != null || values.mobile_number != undefined) {
            let data = {
                ParkTicketQuantity: values.ParkTicketQuantity == "" ? 0 : values.ParkTicketQuantity,
                ParkTicketnetAmount: values.ParkTicketnetAmount == "" ? 0 : values.ParkTicketnetAmount,
                ParkTotalQuantity: values.ParkTotalQuantity == "" ? 0 : values.ParkTotalQuantity,
                mobile_number: values.mobile_number,
                totalAllExpenseTicket: values.totalAllExpenseTicket == "" ? 0 : values.totalAllExpenseTicket,
                park_netAmount: values.park_netAmount == "" ? 0 : values.park_netAmount,
                totalAllExpenseParking: values.totalAllExpenseParking == "" ? 0 : values.totalAllExpenseParking,
                grandAmount: values.grandTotalAmountAll == "" ? 0 : values.grandTotalAmountAll,
                total_allow_person:values.total_allow_person == ""? 0: values.total_allow_person,
                startDate: values.start_date,
                endDate: values.end_date,
            }
            sendSms(data).then((response) => {
                if (response.data.status == true) {
                    toastr.success(response.data.message);
                    this.setState(prevState => ({
                        modal_scroll: !prevState.modal_scroll,
                    }));
                }
            })
                .catch((err) => {
                    this.state.loading = false;
                    console.log(err);
                })
            this.form && this.form.reset();
        }
    }


    fetchCompanyInfo = () => {

        companyInfo().then((response) => {
            this.setState({
                company: response.data
            })
        })
            .catch((err) => {
                console.log(err);
            })

    }

    autofillNumber = (phone) => {
        this.setState({
            autoNumber: phone
        })
    }


    downloadPDF = (ticketSummary, reportDetails, netAmount, total_quantity, accountInformation, mobile, grandQuantity, grandAmount, grandPrice, startDate, endDate) => {

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFont('Nikosh'); //

        doc.setFontSize(15);
        // const Information = [{ header: "", name: accountInformation.park_name_en }, { header: "", name: accountInformation.address }, { header: "ইজারাদার", name: accountInformation.lease_holder }, { header: "স্মারক নং", name: accountInformation.memorandum_no }, { header: "ফোন:", name: mobile }, { header: "প্রবেশ টিকেট:", name: accountInformation.lease_year }]
        // const title = Information.map(header => [header.header, header.name]);


        const headers2 = [["No", "Date & Time", "Token", "Ticket Type", "Price", "Quantity", "Amount", "Is Family", "Operate By", "Device"]];
        const headers1 = [["TICKET TYPE", "PRICE", "Quantity", "Net Amount"]];
        const summaryTotal = [["", "Total", total_quantity, netAmount]]
        const data = ticketSummary.map(elt => [elt.ticket_type, elt.rate, elt.total_ticket_qty, elt.total_income]);
        const merged = [...data, ...summaryTotal]

        const data2 = reportDetails.map((ticket, index) =>
            [index + 1,
            moment.unix(ticket.ticket_entry_date_ime / 1000).format("DD MMM YYYY hh:mm a"),
            ticket.ticket_no,
            ticket.park_rate_type == null || ticket.park_rate_type == "" ? "-" : ticket.park_rate_type.ticket_type,
            ticket.ticket_price,
                1,
            ticket.ticket_price,
            ticket.family_id == null || ticket.family_id == "" ? "-" : ticket.family_id,
            ticket.insert_user == null || ticket.insert_user == "" ? ticket.insert_by_id : ticket.insert_user.name,
            ticket.api_device_name == null || ticket.api_device_name == "" ? "-" : ticket.api_device_name]
        )

        let grandTolal = [["", "", "", "Grand Total", grandPrice, grandQuantity, grandAmount, "", "", ""]];

        const merged2 = [...data2, ...grandTolal]

        let content = {
            startY: 180,
            head: headers1,
            body: merged
        };

        let content1 = {
            startY: 300,
            head: headers2,
            body: merged2
        };


        doc.text(accountInformation.park_name_en, marginLeft, 40);
        doc.text(accountInformation.address, marginLeft, 60);
        doc.text("ইজারাদার: " + accountInformation.lease_holder, marginLeft, 80);
        doc.text("স্মারক নং " + accountInformation.memorandum_no, marginLeft, 100);
        doc.text("ফোন: " + mobile, marginLeft, 120);
        doc.text("প্রবেশ টিকেট: " + accountInformation.lease_year, marginLeft, 140);
        doc.text("তারিখ: " + startDate + " থেকে " + endDate, marginLeft, 160);
        doc.autoTable(content);
        doc.autoTable(content1);
        doc.save("report.pdf")
    }

    render() {
        if (this.state.loading) return <Loader />;

        let vehicleTypeList = [];
        let rateTypeList = [];
        let reportDetails = [];
        let ticketSummary = [];
        let parkingSummary = [];
        let mobile = '';
        let totalExpense = 0;
        let total_quantity = 0;
        let netAmount = 0;
        let ticket_totalExpense = 0;
        let parking_totalExpense = 0;
        let ticket_service_id = "";
        let parking_service_id = "";
        let accountInformation = [];
        let parkingInformation = [];
        let ticketExpenseSummary = [];
        if (this.state.hasOwnProperty('accountInfo')) {
            accountInformation = this.state.accountInfo.park_account_info[0];
            mobile = this.state.accountInfo.mobile;
        }
        if (this.state.hasOwnProperty('parkingAccountInfo')) {
            parkingInformation = this.state.parkingAccountInfo;
        }

        let park_total_quantity = 0;
        let park_netAmount = 0;
        let park_totalHour = 0;

        let PhoneNumber = [
            {
                autoNumber: '01819824497',
            },
            {
                autoNumber: '01713600057',
            },
            {
                autoNumber: '01816515240',
            },
        ]

        let expenseList = [];
        let parkTicketExpense = [];
        let parkingExpense = [];
        let expenseOthersAll = [];
        let otherTicketExpense = [];
        let otherParkingExpense = [];
        let otherTicketTotal = 0;
        let otherParkingTotal = 0;
        let totalAllExpenseTicket = 0;
        let totalAllExpenseParking = 0;
        // if (this.state.hasOwnProperty('details_report_first_ten')) {
        //     detailsReportFirst = this.state.details_report_first_ten
        // }
        // if (this.state.hasOwnProperty('details_report_last_ten')) {
        //     detailsReportLast = this.state.details_report_last_ten
        // }
        // if (this.state.hasOwnProperty('park_ratewise_income')) {
        //     parkIncome = this.state.park_ratewise_income
        // }

        // if (this.state.hasOwnProperty('ticket')) {
        //     ticketQuantity = this.state.ticket.total_ticket
        //     ticketAmount = this.state.ticket.total_ticket_sum
        // }
        // console.log('detailsReport1', detailsReportFirst)
        // console.log('detailsReport2', detailsReportLast)
        // console.log('parkIncome', parkIncome)


        // if (this.state.hasOwnProperty('detailsReport')) {
        //     reportDetails = this.state.detailsReport;

        //     reportDetails && reportDetails.map((element, index) => {
        //         grandPrice += element.ticket_price;
        //         grandAmount += element.ticket_price;
        //     });

        // }
        // if (this.state.hasOwnProperty('totalTicket')) {
        //     grandQuantity = this.state.totalTicket.total_ticket;
        // }    
        let consider_person = 0;
        if (this.state.hasOwnProperty('ticketSummary')) {
            consider_person = this.state.ticketSummary.consider_person*1;
            ticketSummary = this.state.ticketSummary.park_ratewise_income;
            ticket_service_id = this.state.ticketSummary.f_registered_service_id == undefined || this.state.ticketSummary.f_registered_service_id == null ? "" : this.state.ticketSummary.f_registered_service_id[0];
            ticketSummary && ticketSummary.map((element, index) => {
                total_quantity += element.total_ticket_qty;
                netAmount += element.total_income * 1;
            });
        }
        let specialETicket  = 0;
        if (this.state.hasOwnProperty('specialETicket')) {
            specialETicket = this.state.specialETicket.total_ticket*1;
        }

        let allowPerson = 0;
        allowPerson = specialETicket+consider_person;


        let total_parking_missing_balance  = 0;
        let total_park_missing_balance  = 0;
        let total_missing_balance = 0;

        if (this.state.hasOwnProperty('MissingBalance')) {
            total_missing_balance = this.state.MissingBalance.total_missing_balance*1;
            total_park_missing_balance = this.state.MissingBalance.total_missing_park_amount*1;
            total_parking_missing_balance = this.state.MissingBalance.total_missing_parking_amount*1;
        }


        if (this.state.hasOwnProperty('parkingSummary')) {
            parkingSummary = this.state.parkingSummary;
            parking_service_id = this.state.parkingSummary == undefined || this.state.parkingSummary == null || this.state.parkingSummary.length == 0 ? "" : this.state.parkingSummary[0].f_registered_service_id;
            parkingSummary && parkingSummary.map((element, index) => {
                park_total_quantity += element.total_vehicle;
                park_netAmount += element.total_receive_amount * 1;
                park_totalHour += element.total_hour_spend;

            });
        }
        // let individualExpense = [];
        // if (this.state.hasOwnProperty('expense')) {
        //     individualExpense = this.state.expense.individualExpense;
        // }

        if (this.state.hasOwnProperty('expenseSummary')) {
            expenseList = this.state.expenseSummary;
            // expenseList && expenseList.map(expense => {
            //     totalExpense = totalExpense + expense.total_expense_amount * 1;
            // })

            // expense list for park-ticket 
            parkTicketExpense = expenseList && expenseList.filter(expense => expense.registered_service_id == ticket_service_id).map((expenseListSummary, index) => expenseListSummary)
            parkingExpense = expenseList && expenseList.filter(expense => expense.registered_service_id == parking_service_id).map((expenseListSummary, index) => expenseListSummary)

            // ticket-total-expense
            expenseList && expenseList.filter(expense => expense.registered_service_id == ticket_service_id).map((expenseListSummary, index) => {
                ticket_totalExpense += expenseListSummary.total_expense_amount * 1;
            }
            )
            // parking-total-expense
            expenseList && expenseList.filter(expense => expense.registered_service_id == parking_service_id).map((expenseListSummary, index) => {
                parking_totalExpense += expenseListSummary.total_expense_amount * 1;
            }
            )



        }

        if (this.state.hasOwnProperty('expenseOthers')) {
            expenseOthersAll = this.state.expenseOthers;
            otherTicketExpense = expenseOthersAll && expenseOthersAll.filter(expense => expense.f_registered_service_id == ticket_service_id).map((otherTicketExpense, index) => otherTicketExpense)
            otherParkingExpense = expenseOthersAll && expenseOthersAll.filter(expense => expense.f_registered_service_id == parking_service_id).map((otherParkingExpense, index) => otherParkingExpense)

            expenseOthersAll && expenseOthersAll.filter(expense => expense.f_registered_service_id == ticket_service_id).map((expenseOthersAllSummary, index) => {
                otherTicketTotal += expenseOthersAllSummary.total_expense_amount * 1;
            }
            )
            // parking-total-expense
            expenseOthersAll && expenseOthersAll.filter(expense => expense.f_registered_service_id == parking_service_id).map((expenseOthersAllSummary, index) => {
                otherParkingTotal += expenseOthersAllSummary.total_expense_amount * 1;
            }
            )

        }

        totalAllExpenseTicket = ticket_totalExpense + otherTicketTotal;
        totalAllExpenseParking = parking_totalExpense + otherParkingTotal;
        totalExpense = ticket_totalExpense + parking_totalExpense + otherTicketTotal + otherParkingTotal;
        // let grandQuantity = total_quantity + park_total_quantity;
        let grandAmount = (netAmount + park_netAmount) - totalExpense - total_missing_balance;

        var finalEnglishToBanglaNumber = {
            0: "০",
            1: "১",
            2: "২",
            3: "৩",
            4: "৪",
            5: "৫",
            6: "৬",
            7: "৭",
            8: "৮",
            9: "৯",
        };

        String.prototype.getDigitBanglaFromEnglish = function () {
            var retStr = this;
            for (var x in finalEnglishToBanglaNumber) {
                retStr = retStr.replace(
                    new RegExp(x, "g"),
                    finalEnglishToBanglaNumber[x]
                );
            }
            return retStr;
        };


        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <title>Summary-Report</title>
                        <Breadcrumbs title="Settings" breadcrumbItem="Summary Report" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>

                                            <FormGroup row>

                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <label>Date Range</label>
                                                    <div class="row">
                                                        <div className="col pr-0">
                                                            <DatePicker
                                                                selected={this.state.startDate}
                                                                className="form-control"
                                                                onChange={date => this.setState({ startDate: date })}
                                                                selectsStart
                                                                startDate={this.state.startDate}
                                                                endDate={this.state.endDate}
                                                            />
                                                        </div>
                                                        <div className="col pl-0">
                                                            <DatePicker
                                                                selected={this.state.endDate}
                                                                className="form-control"
                                                                onChange={date => this.setState({ endDate: date })}
                                                                selectsEnd
                                                                startDate={this.state.startDate}
                                                                endDate={this.state.endDate}
                                                                minDate={this.state.startDate}
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <Button type="submit" color="success" className="btn btn-md">Search</Button>
                                                        </div>

                                                        <div className="col">
                                                        
                                                            
                                                        </div>

                                                        
                                                    </div>

                    


                                                    <AvField type="hidden" name="start_date" id="start_date" value={this.state.startDate} />
                                                    <AvField type="hidden" name="end_date" id="end_date" value={this.state.endDate} />
                                                </div>
                                            </FormGroup>

                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardHeader>
                                        <FormGroup row>
                                            <div class="col-md-1">
                                                <button size="lg" className="btn btn-md btn-primary float-left" onClick={(e) => this.tog_scroll()}>SEND SMS</button>
                                            </div>
                                            <div class="col-md-5">
                                            <a href="/MissingList">
                                                <button size="lg" className="btn btn-md btn-primary float-left" onClick={(e) => this.tog_scroll()}>
                                                        Missing List
                                                </button>
                                                </a>
                                            </div>
                                            <div class="col-md-6">
                                                {/* {reportDetails.length == 0 ? "" :
                                                    <button size="lg" className="btn btn-md  ml-2 text-light  float-right" style={{ backgroundColor: '#4E949B' }} onClick={() => this.downloadPDF(ticketSummary, reportDetails, total_quantity, netAmount, accountInformation, mobile.getDigitBanglaFromEnglish(), grandQuantity, grandAmount, grandPrice, moment(new Date(this.state.startDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish(), moment(new Date(this.state.endDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish())}>PDF</button>} */}
                                                <button size="lg" className="btn btn-md btn-primary float-right" onClick={(e) => this.printDiv(e, this.state.startDate, this.state.endDate)}>PRINT</button>
                                            </div>
                                        </FormGroup>
                                        <Modal
                                            className={"modal-dialog-centered"}
                                            isOpen={this.state.modal_scroll}
                                            toggle={this.tog_scroll}
                                            scrollable={true}
                                        >
                                            <div className="modal-header">
                                                <h5 className="modal-title mt-0">Send Report Summary Data</h5>
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        this.setState({ modal_scroll: false })
                                                    }
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleSendSMS(e, v) }} ref={c => (this.form = c)}>
                                                    {this.props.msg && this.props.msg ? (
                                                        <Alert color="success">
                                                            SMS Send Successfully!
                                                        </Alert>
                                                    ) : null}

                                                    <div className="col-md-12">
                                                        <AvField className="form-control" label="Mobile Number" type="text" name="mobile_number" value={this.state.autoNumber} id="mobile_number" required />
                                                        <p><i>Suggested: </i>
                                                            {PhoneNumber && PhoneNumber.map(mainNumber =>
                                                                <span className="text-primary" onClick={() => this.autofillNumber(mainNumber.autoNumber)} style={{ cursor: 'pointer' }}>
                                                                    {mainNumber.autoNumber} ,
                                                                </span>
                                                            )}
                                                        </p>
                                                        <AvField type="hidden" name="ParkTicketnetAmount" id="ParkTicketnetAmount" value={netAmount} />
                                                        <AvField type="hidden" name="ParkTicketQuantity" id="ParkTicketQuantity" value={total_quantity} />
                                                        <AvField type="hidden" name="totalAllExpenseTicket" id="totalAllExpenseTicket" value={totalAllExpenseTicket} />
                                                        <AvField type="hidden" name="ParkTotalQuantity" id="ParkTotalQuantity" value={park_total_quantity} />
                                                        <AvField type="hidden" name="park_netAmount" id="park_netAmount" value={park_netAmount} />
                                                        <AvField type="hidden" name="totalAllExpenseParking" id="totalAllExpenseParking" value={totalAllExpenseParking} />
                                                        <AvField type="hidden" name="grandTotalAmountAll" id="grandTotalAmountAll" value={grandAmount} />
                                                        <AvField type="hidden" name="start_date" id="start_date" value={this.state.startDate} />
                                                        <AvField type="hidden" name="end_date" id="end_date" value={this.state.endDate} />
                                                        <AvField type="hidden" name="total_allow_person" id="total_allow_person" value={allowPerson} />

                                                    </div>
                                                    <div className="text-center mt-4">
                                                        <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                        <button type="submit" className="btn btn-success save-event"><i className="bx bxs-paper-plane "></i> Send</button>
                                                    </div>
                                                </AvForm>
                                            </div>
                                        </Modal>
                                    </CardHeader>
                                    {/* { reportDetails.length == 0 ? */}
                                    <>
                                        <CardBody>
                                            <div id="example">

                                                <div className="row waterMark">
                                                    <div className="col-12 col-lg-6 col-md-6" align="center">
                                                        <h2 className="text-center"><b>{accountInformation.park_name_en}</b></h2>
                                                        <p className="text-center mb-2">{accountInformation.address}</p>
                                                        <div className="row mb-2">
                                                            <div className="col-md-7">
                                                                <p className="text-left mb-0"><span className="font-weight-bold ">Lease Holder: </span>{accountInformation.lease_holder}</p>
                                                                <p className="text-left mb-0"><span className="font-weight-bold ">Memorandum No: </span>{accountInformation.memorandum_no}</p>
                                                                <p className="text-left mb-0"><span className="font-weight-bold ">Phone: </span>{mobile}</p>
                                                            </div>
                                                            <div className="col-md-5 d-flex align-items-end justify-content-end">
                                                                <div>
                                                                    <p className="text-left mb-0 "><span className="font-weight-bold ">Lease Year: {accountInformation.lease_year}</span></p>
                                                                    <p className="text-left mb-0 "><span className="font-weight-bold ">Date: </span>{moment(new Date(this.state.startDate)).format("DD/MM/YYYY")} TO {moment(new Date(this.state.endDate)).format("DD/MM/YYYY")}</p>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        {/* <span className="text-center"><span className="font-weight-bold">ইজারাদার: </span>{accountInformation.lease_holder}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">স্মারক নং: </span>{accountInformation.memorandum_no}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">ফোন: </span>{mobile.getDigitBanglaFromEnglish()}</span>
                                                        <h6><span className="font-weight-bold">প্রবেশ টিকেট: {accountInformation.lease_year}</span></h6>
                                                        <p><span className="font-weight-bold">তারিখ: </span>{moment(new Date(this.state.startDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()} থেকে {moment(new Date(this.state.endDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()}</p>
                                                        <br></br> */}
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={4} style={{ textAlign: 'center' }}>Park Ticket Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Ticket Type</th>
                                                                    <th className="text-center">Price</th>
                                                                    <th className="text-center">Quantity</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ticketSummary && ticketSummary.map((ticketSummary, index) =>
                                                                    <tr key={ticketSummary.id.toString()}>
                                                                        <td className="text-center">{ticketSummary.ticket_type}</td>
                                                                        <td className="text-right">{ticketSummary.rate} TK.</td>
                                                                        <td className="text-right">{ticketSummary.total_ticket_qty} Qty</td>
                                                                        <td className="text-right">{ticketSummary.total_income} TK.</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold"> Total</td>
                                                                    <td className="text-right font-weight-bold">{total_quantity} Qty</td>
                                                                    <td className="text-right font-weight-bold">{netAmount} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={3} style={{ textAlign: 'center' }}>Expense Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Service Type</th>
                                                                    <th className="text-center">Expense Type</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {parkTicketExpense.length < 1 && otherTicketExpense.length < 1 ?
                                                                    <tr>
                                                                        <td className="text-center text-secondary font-weight-bold" colSpan="3" style={{ fontSize: '20px' }}>No Data Found</td>
                                                                    </tr> :
                                                                    <>
                                                                        {parkTicketExpense.map((expenseListSummary, index) =>
                                                                            <tr key={expenseListSummary.expense_type_id.toString()}>
                                                                                <td className="text-center">{expenseListSummary.service_name_en}</td>
                                                                                <td className="text-center">{expenseListSummary.expense_type_name}</td>
                                                                                <td className="text-right">{expenseListSummary.total_expense_amount} TK.</td>
                                                                            </tr>
                                                                        )}
                                                                        {otherTicketExpense && otherTicketExpense.map((summary, index) =>
                                                                            <tr key={index}>
                                                                                <td className="text-center">{summary.service_expense == null ? '*' : summary.service_expense.service_name_en}</td>
                                                                                <td className="text-center">{summary.expense_type == null ? '*' : summary.expense_type_name}</td>
                                                                                <td className="text-right">{summary.total_expense_amount} TK.</td>
                                                                            </tr>
                                                                        )}

                                                                    </>
                                                                }
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold"> Total</td>

                                                                    <td className="text-right font-weight-bold">{totalAllExpenseTicket} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>

                                                        <Table bordered responsive className="table-report">
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }} className="font-weight-bold">Allow Person</td>
                                                                    <td style={{ textAlign: 'left' }} className="font-weight-bold">{allowPerson} Persons</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">Consider With Ticket</td>
                                                                    <td className="text-left">{consider_person} Persons</td>

                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">Special ETicket</td>
                                                                    <td className="text-left">{specialETicket} Persons</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>

                                                    <div className="col-12 col-lg-6 col-md-6" align="center">
                                                        <h2 className="text-center"><b>{parkingInformation.company_name_en}</b></h2>
                                                        <p className="text-center mb-2">{parkingInformation.address_en}</p>
                                                        <div className="row mb-2">
                                                            <div className="col-md-7">
                                                                <p className="text-left mb-0"><span className="font-weight-bold ">Lease Holder: </span><span>{parkingInformation.lease_holder}</span></p>
                                                                <p className="text-left mb-0"><span className="font-weight-bold ">Memorandum No: </span><span>{parkingInformation.memorandum_no}</span></p>
                                                                <p className="text-left mb-0"><span className="font-weight-bold ">Phone: </span>{mobile}</p>
                                                            </div>
                                                            <div className="col-md-5 d-flex align-items-end justify-content-end">
                                                                <div>
                                                                    <p className="text-left mb-0 "><span className="font-weight-bold ">Lease Year: {parkingInformation.lease_year}</span></p>
                                                                    <p className="text-left mb-0 "><span className="font-weight-bold ">Date: </span>{moment(new Date(this.state.startDate)).format("DD/MM/YYYY")} TO {moment(new Date(this.state.endDate)).format("DD/MM/YYYY")}</p>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        {/* <h2 className="text-center"><b>{parkingInformation.company_name_en}</b></h2>
                                                        <span className="text-center">{parkingInformation.address_en}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">ইজারাদার: </span>{parkingInformation.lease_holder}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">স্মারক নং: </span>{parkingInformation.memorandum_no}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">ফোন: </span>{mobile.getDigitBanglaFromEnglish()}</span>
                                                        <h6><span className="font-weight-bold">প্রবেশ টিকেট: {parkingInformation.lease_year}</span></h6>
                                                        <p><span className="font-weight-bold">তারিখ: </span>{moment(new Date(this.state.startDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()} থেকে {moment(new Date(this.state.endDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()}</p>
                                                        <br></br> */}
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={4} style={{ textAlign: 'center' }}>Parking Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Vehicle Type</th>
                                                                    {/* <th className="text-center">Price</th> */}
                                                                    <th className="text-center">Hours</th>
                                                                    <th className="text-center">Quantity</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {parkingSummary && parkingSummary.map((parkingSummary, index) =>
                                                                    <tr key={parkingSummary.id.toString()}>
                                                                        <td className="text-center">{parkingSummary.vehicle_type_name_en}</td>
                                                                        <td className="text-right">{parkingSummary.total_hour_spend} Hours</td>
                                                                        <td className="text-right">{parkingSummary.total_vehicle} Qty</td>
                                                                        <td className="text-right">{parkingSummary.total_receive_amount} TK.</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="1" className="text-right font-weight-bold"> Total</td>
                                                                    {park_totalHour <= 0 ?
                                                                        <td className="text-center font-weight-bold">-</td> :

                                                                        <td className="text-right font-weight-bold">{park_totalHour} Hours</td>
                                                                    }


                                                                    <td className="text-right font-weight-bold">{park_total_quantity} Qty</td>
                                                                    <td className="text-right font-weight-bold">{park_netAmount} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={3} style={{ textAlign: 'center' }}>Expense Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Service Type</th>
                                                                    {/* <th className="text-center">Price</th> */}
                                                                    <th className="text-center">Expense Type</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {parkingExpense.length < 1 && otherParkingExpense.length < 1 ?
                                                                    <tr>
                                                                        <td className="text-center text-secondary font-weight-bold" colSpan="3" style={{ fontSize: '20px' }}>No Data Found</td>
                                                                    </tr> :
                                                                    <>
                                                                        {parkingExpense.map((expenseListSummary, index) =>
                                                                            <tr key={expenseListSummary.expense_type_id.toString()}>
                                                                                <td className="text-center">{expenseListSummary.service_name_en}</td>
                                                                                <td className="text-center">{expenseListSummary.expense_type_name}</td>
                                                                                <td className="text-right">{expenseListSummary.total_expense_amount} TK.</td>
                                                                            </tr>
                                                                        )}
                                                                        {otherParkingExpense && otherParkingExpense.map((summary, index) =>
                                                                            <tr key={index}>
                                                                                <td className="text-center">{summary.service_expense == null ? '*' : summary.service_expense.service_name_en}</td>
                                                                                <td className="text-center">{summary.expense_type == null ? '*' : summary.expense_type_name}</td>
                                                                                <td className="text-right">{summary.total_expense_amount} TK.</td>
                                                                            </tr>
                                                                        )}
                                                                    </>}
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold"> Total</td>

                                                                    <td className="text-right font-weight-bold">{totalAllExpenseParking} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>

                                                        <Table bordered responsive className="table-report">
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }} className="font-weight-bold">Missing Balance</td>
                                                                    <td style={{ textAlign: 'left' }} className="font-weight-bold">{total_missing_balance} BDT</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">PARK</td>
                                                                    <td className="text-left">{total_park_missing_balance} BDT</td>

                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">PARKING</td>
                                                                    <td className="text-left">{total_parking_missing_balance} BDT</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>

                                                    <div className="col-12 col-lg-12  mt-3" align="center">

                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr>
                                                                    <th rowSpan="2" className="text-center font-weight-bold align-middle"><span style={{ fontSize: '20px' }}>Grand Total</span><br /><span>(Park Ticket  + Parking )-(Park Ticket Expense  + Parking Expense + Missing Balance ) </span><br /></th>
                                                                </tr>
                                                                <tr>
                                                                    <th className="text-center font-weight-bold " rowSpan="2" style={{ width: '30rem', fontSize: '36px' }}>{grandAmount} TK.</th>
                                                                </tr>
                                                            </thead>

                                                        </Table>
                                                    </div>

                                                </div>
                                                {/* <div className="row"> */}
                                                {/* <div className="col-12 col-lg-6 offset-lg-3" align="center">
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={4} style={{ textAlign: 'center' }}>Ticket Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Ticket Type</th>
                                                                    <th className="text-center">Price</th>
                                                                    <th className="text-center">Quantity</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ticketSummary && ticketSummary.map((ticketSummary, index) =>
                                                                    <tr key={ticketSummary.id.toString()}>
                                                                        <td className="text-center">{ticketSummary.ticket_type}</td>
                                                                        <td className="text-right">{ticketSummary.rate} TK.</td>
                                                                        <td className="text-right">{ticketSummary.total_ticket_qty} Qty</td>
                                                                        <td className="text-right">{ticketSummary.total_income} TK.</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold"> Total</td>
                                                                    <td className="text-right font-weight-bold">{total_quantity} Qty</td>
                                                                    <td className="text-right font-weight-bold">{netAmount} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div> */}

                                                {/* <div className="col-12 col-lg-6 offset-lg-3 mt-3" align="center">
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={4} style={{ textAlign: 'center' }}>Parking Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Vehicle Type</th>
                                                                    <th className="text-center">Hours</th>
                                                                    <th className="text-center">Quantity</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {parkingSummary && parkingSummary.map((parkingSummary, index) =>
                                                                    <tr key={parkingSummary.id.toString()}>
                                                                        <td className="text-center">{parkingSummary.vehicle_type_name_en}</td>
                                                                        <td className="text-right">{parkingSummary.total_hour_spend} Hours</td>
                                                                        <td className="text-right">{parkingSummary.total_vehicle} Qty</td>
                                                                        <td className="text-right">{parkingSummary.total_receive_amount} TK.</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="1" className="text-right font-weight-bold"> Total</td>
                                                                    {park_totalHour <= 0 ?
                                                                        <td className="text-center font-weight-bold">-</td> :

                                                                        <td className="text-right font-weight-bold">{park_totalHour} Hours</td>
                                                                    }


                                                                    <td className="text-right font-weight-bold">{park_total_quantity} Qty</td>
                                                                    <td className="text-right font-weight-bold">{park_netAmount} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div> */}
                                                {/* expense */}
                                                {/* <div className="col-12 col-lg-6 offset-lg-3 mt-3" align="center">
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={3} style={{ textAlign: 'center' }}>Expense Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Service Type</th>
                                                                    <th className="text-center">Expense Type</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {expenseList && expenseList.map((expenseListSummary, index) =>
                                                                    <tr key={expenseListSummary.expense_type_id.toString()}>
                                                                        <td className="text-center">{expenseListSummary.service_name_en}</td>
                                                                        <td className="text-center">{expenseListSummary.expense_type_name}</td>
                                                                        <td className="text-right">{expenseListSummary.total_expense_amount} TK.</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold"> Total</td>

                                                                    <td className="text-right font-weight-bold">{totalExpense} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div> */}


                                                {/* </div> */}
                                            </div>

                                        </CardBody>

                                        {/* for Print Only */}
                                        <CardBody style={{ display: 'none' }}>
                                            <div id="ForPrint">

                                                {/* <div className="row">
                                                    <div className="col-12 col-lg-6 offset-lg-3" align="center">
                                                        <h2 className="text-center"><b>{accountInformation.park_name_en}</b></h2>
                                                        <span className="text-center">{accountInformation.address}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">ইজারাদার: </span>{accountInformation.lease_holder}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">স্মারক নং: </span>{accountInformation.memorandum_no}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">ফোন: </span>{mobile.getDigitBanglaFromEnglish()}</span>
                                                        <h6><span className="font-weight-bold">প্রবেশ টিকেট: {accountInformation.lease_year}</span></h6>
                                                        <p><span className="font-weight-bold">তারিখ: </span>{moment(new Date(this.state.startDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()} থেকে {moment(new Date(this.state.endDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()}</p>
                                                        <br></br>
                                                    </div>

                                                </div> */}
                                                {/* <div className="row waterMark">
                                                    <div className="col-12 col-lg-6 offset-lg-3" align="center">
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={4} style={{ textAlign: 'center' }}>Ticket Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Ticket Type</th>
                                                                    <th className="text-center">Price</th>
                                                                    <th className="text-center">Quantity</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ticketSummary && ticketSummary.map((ticketSummary, index) =>
                                                                    <tr key={ticketSummary.id.toString()}>
                                                                        <td className="text-center">{ticketSummary.ticket_type}</td>
                                                                        <td className="text-right">{ticketSummary.rate} TK.</td>
                                                                        <td className="text-right">{ticketSummary.total_ticket_qty} Qty</td>
                                                                        <td className="text-right">{ticketSummary.total_income} TK.</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold"> Total</td>
                                                                    <td className="text-right font-weight-bold">{total_quantity} Qty</td>
                                                                    <td className="text-right font-weight-bold">{netAmount} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>



                                                    <div className="col-12 col-lg-6 offset-lg-3 mt-3" align="center">
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={4} style={{ textAlign: 'center' }}>Parking Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Vehicle Type</th>
                                                                    <th className="text-center">Hours</th>
                                                                    <th className="text-center">Quantity</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {parkingSummary && parkingSummary.map((parkingSummary, index) =>
                                                                    <tr key={parkingSummary.id.toString()}>
                                                                        <td className="text-center">{parkingSummary.vehicle_type_name_en}</td>
                                                                        <td className="text-right">{parkingSummary.total_hour_spend} Hours</td>
                                                                        <td className="text-right">{parkingSummary.total_vehicle} Qty</td>
                                                                        <td className="text-right">{parkingSummary.total_receive_amount} TK.</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="1" className="text-right font-weight-bold"> Total</td>
                                                                    {park_totalHour <= 0 ?
                                                                        <td className="text-center font-weight-bold">-</td> :

                                                                        <td className="text-right font-weight-bold">{park_totalHour} Hours</td>
                                                                    }
                                                                    <td className="text-right font-weight-bold">{park_total_quantity} Qty</td>
                                                                    <td className="text-right font-weight-bold">{park_netAmount} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <div className="col-12 col-lg-6 offset-lg-3 mt-3" align="center">
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={3} style={{ textAlign: 'center' }}>Expense Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Service Type</th>
                                                                    <th className="text-center">Expense Type</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {expenseList && expenseList.map((expenseListSummary, index) =>
                                                                    <tr key={expenseListSummary.expense_type_id.toString()}>
                                                                        <td className="text-center">{expenseListSummary.service_name_en}</td>
                                                                        <td className="text-center">{expenseListSummary.expense_type_name}</td>
                                                                        <td className="text-right">{expenseListSummary.total_expense_amount} TK.</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold"> Total</td>

                                                                    <td className="text-right font-weight-bold">{totalExpense} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <div className="col-12 col-lg-6 offset-lg-3 mt-3" align="center">
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr>
                                                                    <th rowSpan="2" className="text-center font-weight-bold align-middle">Grand Total</th>
                                                                    <th className="text-center font-weight-bold ">Net Amount</th>
                                                                </tr>
                                                                <tr>
                                                                    <th className="text-center font-weight-bold ">{grandAmount} TK.</th>
                                                                </tr>
                                                            </thead>

                                                        </Table>
                                                    </div>
                                                </div> */}

                                                <div className="row waterMark">
                                                    <div className="col-12 col-lg-6 col-md-6" align="center">
                                                        <h2 className="text-center"><b>{accountInformation.park_name_en}</b></h2>
                                                        <p className="text-center mb-2">{accountInformation.address}</p>
                                                        <div className="row mb-2">
                                                            <div className="col-md-7">
                                                                <p className="text-left mb-0"><span className="font-weight-bold ">Lease Holder: </span>{accountInformation.lease_holder}</p>
                                                                <p className="text-left mb-0"><span className="font-weight-bold ">Memorandum No: </span>{accountInformation.memorandum_no}</p>
                                                                <p className="text-left mb-0"><span className="font-weight-bold ">Phone: </span>{mobile}</p>
                                                            </div>
                                                            <div className="col-md-5 d-flex align-items-end justify-content-end">
                                                                <div>
                                                                    <p className="text-left mb-0 "><span className="font-weight-bold ">Lease Year: {accountInformation.lease_year}</span></p>
                                                                    <p className="text-left mb-0 "><span className="font-weight-bold ">Date: </span>{moment(new Date(this.state.startDate)).format("DD/MM/YYYY")} TO {moment(new Date(this.state.endDate)).format("DD/MM/YYYY")}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <span className="text-center"><span className="font-weight-bold">ইজারাদার: </span>{accountInformation.lease_holder}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">স্মারক নং: </span>{accountInformation.memorandum_no}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">ফোন: </span>{mobile.getDigitBanglaFromEnglish()}</span>
                                                        <h6><span className="font-weight-bold">প্রবেশ টিকেট: {accountInformation.lease_year}</span></h6>
                                                        <p><span className="font-weight-bold">তারিখ: </span>{moment(new Date(this.state.startDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()} থেকে {moment(new Date(this.state.endDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()}</p>
                                                        <br></br> */}
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={4} style={{ textAlign: 'center' }}>Park Ticket Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Ticket Type</th>
                                                                    <th className="text-center">Price</th>
                                                                    <th className="text-center">Quantity</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ticketSummary && ticketSummary.map((ticketSummary, index) =>
                                                                    <tr key={ticketSummary.id.toString()}>
                                                                        <td className="text-center">{ticketSummary.ticket_type}</td>
                                                                        <td className="text-right">{ticketSummary.rate} TK.</td>
                                                                        <td className="text-right">{ticketSummary.total_ticket_qty} Qty</td>
                                                                        <td className="text-right">{ticketSummary.total_income} TK.</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold"> Total</td>
                                                                    <td className="text-right font-weight-bold">{total_quantity} Qty</td>
                                                                    <td className="text-right font-weight-bold">{netAmount} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={3} style={{ textAlign: 'center' }}>Expense Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Service Type</th>
                                                                    <th className="text-center">Expense Type</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {parkTicketExpense.length < 1 && otherTicketExpense.length < 1 ?
                                                                    <tr>
                                                                        <td className="text-center text-secondary font-weight-bold" colSpan="3" style={{ fontSize: '20px' }}>No Data Found</td>
                                                                    </tr> :
                                                                    <>
                                                                        {parkTicketExpense.map((expenseListSummary, index) =>
                                                                            <tr key={expenseListSummary.expense_type_id.toString()}>
                                                                                <td className="text-center">{expenseListSummary.service_name_en}</td>
                                                                                <td className="text-center">{expenseListSummary.expense_type_name}</td>
                                                                                <td className="text-right">{expenseListSummary.total_expense_amount} TK.</td>
                                                                            </tr>
                                                                        )}
                                                                        {otherTicketExpense && otherTicketExpense.map((summary, index) =>
                                                                            <tr key={index}>
                                                                                <td className="text-center">{summary.service_expense == null ? '*' : summary.service_expense.service_name_en}</td>
                                                                                <td className="text-center">{summary.expense_type == null ? '*' : summary.expense_type_name}</td>
                                                                                <td className="text-right">{summary.total_expense_amount} TK.</td>
                                                                            </tr>
                                                                        )}

                                                                    </>
                                                                }
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold"> Total</td>

                                                                    <td className="text-right font-weight-bold">{totalAllExpenseTicket} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                        <Table bordered responsive className="table-report">
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }} className="font-weight-bold">Allow Person</td>
                                                                    <td style={{ textAlign: 'left' }} className="font-weight-bold">{allowPerson} Persons</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">Consider With Ticket</td>
                                                                    <td className="text-left">{consider_person} Persons</td>

                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">Special ETicket</td>
                                                                    <td className="text-left">{specialETicket} Persons</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <div className="bg-text">
                                                        <img src={logo} className=" w-100 " alt="image" />
                                                    </div>
                                                    <div className="col-12 col-lg-6 col-md-6" align="center">
                                                        <h2 className="text-center"><b>{parkingInformation.company_name_en}</b></h2>
                                                        <p className="text-center mb-2">{parkingInformation.address_en}</p>
                                                        <div className="row mb-2">
                                                            <div className="col-md-7">
                                                                <p className="text-left mb-0"><span className="font-weight-bold ">Lease Holder: </span><span>{parkingInformation.lease_holder}</span></p>
                                                                <p className="text-left mb-0"><span className="font-weight-bold ">Memorandum No: </span><span>{parkingInformation.memorandum_no}</span></p>
                                                                <p className="text-left mb-0"><span className="font-weight-bold ">Phone: </span>{mobile}</p>
                                                            </div>
                                                            <div className="col-md-5 d-flex align-items-end justify-content-end">
                                                                <div>
                                                                    <p className="text-left mb-0 "><span className="font-weight-bold ">Lease Year: {parkingInformation.lease_year}</span></p>
                                                                    <p className="text-left mb-0 "><span className="font-weight-bold ">Date: </span>{moment(new Date(this.state.startDate)).format("DD/MM/YYYY")} TO {moment(new Date(this.state.endDate)).format("DD/MM/YYYY")}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <h2 className="text-center"><b>{parkingInformation.company_name_en}</b></h2>
                                                        <span className="text-center">{parkingInformation.address_en}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">ইজারাদার: </span>{parkingInformation.lease_holder}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">স্মারক নং: </span>{parkingInformation.memorandum_no}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">ফোন: </span>{mobile.getDigitBanglaFromEnglish()}</span>
                                                        <h6><span className="font-weight-bold">প্রবেশ টিকেট: {parkingInformation.lease_year}</span></h6>
                                                        <p><span className="font-weight-bold">তারিখ: </span>{moment(new Date(this.state.startDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()} থেকে {moment(new Date(this.state.endDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()}</p>
                                                        <br></br> */}
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={4} style={{ textAlign: 'center' }}>Parking Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Vehicle Type</th>
                                                                    {/* <th className="text-center">Price</th> */}
                                                                    <th className="text-center">Hours</th>
                                                                    <th className="text-center">Quantity</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {parkingSummary && parkingSummary.map((parkingSummary, index) =>
                                                                    <tr key={parkingSummary.id.toString()}>
                                                                        <td className="text-center">{parkingSummary.vehicle_type_name_en}</td>
                                                                        <td className="text-right">{parkingSummary.total_hour_spend} Hours</td>
                                                                        <td className="text-right">{parkingSummary.total_vehicle} Qty</td>
                                                                        <td className="text-right">{parkingSummary.total_receive_amount} TK.</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="1" className="text-right font-weight-bold"> Total</td>
                                                                    {park_totalHour <= 0 ?
                                                                        <td className="text-center font-weight-bold">-</td> :

                                                                        <td className="text-right font-weight-bold">{park_totalHour} Hours</td>
                                                                    }


                                                                    <td className="text-right font-weight-bold">{park_total_quantity} Qty</td>
                                                                    <td className="text-right font-weight-bold">{park_netAmount} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={3} style={{ textAlign: 'center' }}>Expense Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Service Type</th>
                                                                    {/* <th className="text-center">Price</th> */}
                                                                    <th className="text-center">Expense Type</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {parkingExpense.length < 1 && otherParkingExpense.length < 1 ?
                                                                    <tr>
                                                                        <td className="text-center text-secondary font-weight-bold" colSpan="3" style={{ fontSize: '20px' }}>No Data Found</td>
                                                                    </tr> :
                                                                    <>
                                                                        {parkingExpense.map((expenseListSummary, index) =>
                                                                            <tr key={expenseListSummary.expense_type_id.toString()}>
                                                                                <td className="text-center">{expenseListSummary.service_name_en}</td>
                                                                                <td className="text-center">{expenseListSummary.expense_type_name}</td>
                                                                                <td className="text-right">{expenseListSummary.total_expense_amount} TK.</td>
                                                                            </tr>
                                                                        )}
                                                                        {otherParkingExpense && otherParkingExpense.map((summary, index) =>
                                                                            <tr key={index}>
                                                                                <td className="text-center">{summary.service_expense == null ? '*' : summary.service_expense.service_name_en}</td>
                                                                                <td className="text-center">{summary.expense_type == null ? '*' : summary.expense_type_name}</td>
                                                                                <td className="text-right">{summary.total_expense_amount} TK.</td>
                                                                            </tr>
                                                                        )}
                                                                    </>}
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold"> Total</td>

                                                                    <td className="text-right font-weight-bold">{totalAllExpenseParking} TK.</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>

                                                    <div className="col-12 col-lg-12  mt-3" align="center">

                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr>
                                                                    <th rowSpan="2" className="text-center font-weight-bold align-middle"><span style={{ fontSize: '20px' }}>Grand Total</span><br /><span>(Park Ticket  + Parking )-(Park Ticket Expense  + Parking Expense )</span><br /></th>
                                                                </tr>
                                                                <tr>
                                                                    <th className="text-center font-weight-bold " rowSpan="2" style={{ width: '30rem', fontSize: '36px' }}>{grandAmount} TK.</th>
                                                                </tr>
                                                            </thead>

                                                        </Table>
                                                    </div>

                                                </div>
                                            </div>

                                        </CardBody>
                                    </>

                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.ParkingSlot;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createParkIn })(SummaryReport));
