import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CustomInput, CardHeader, Label, FormGroup } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Common/CustomPagination";
import { DataTabeSearch } from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createUser, userList, editUser } from '../../../store/user/actions';
import { createRole, roleList, editRole, getPermissionGroupList, roleShow } from '../../../store/role/actions';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            match: false,
            modal_scroll: false,
            all_roles: [],
            user: {
                roles: [],
                status: 1

            }
        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        this.fetchuserList();
        this.fetchroleList();
    }


    fetchuserList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        userList(options).then((response) => {

            this.setState({
                userList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchroleList = () => {
        let options = {
            per_page: 75,
        }
        roleList(options).then((response) => {

            this.setState({
                all_roles: response.data.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (user) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.user = user;
        let roles=[];
        roles = user.roles;
        let roleList = [];
        roles == undefined || roles == null || roles==""?roles=[]:roles.forEach(element => { roleList.push(element.id) });
        this.state.user.roles = roleList;
        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        values.roles = this.state.user.roles;
        values = Object.entries(values).reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {})
        this.props.createUser(values);
        this.form && this.form.reset();
        this.fetchuserList();
    }


    handleEditSubmit(event, values) {
        values.roles = this.state.user.roles;
        values = Object.entries(values).reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {})
        this.props.editUser(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));

        this.fetchuserList();
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchuserList();
        });
    }

    handlePasswordChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchuserList();
        });
    }

    handleChange = (e) => {
        let roles = this.state.user.roles;

        if (e.target.checked === true) {
            roles.push(parseInt(e.target.dataset.id));
        } else {
            roles = roles.filter(function (role) {
                return role !== parseInt(e.target.dataset.id);
            });
        }

        let userCopy = Object.assign({}, this.state.user);
        userCopy["roles"] = roles;

        this.setState({
            user: userCopy
        });

    }

    getRoleInput = (role) => {

        const { user } = this.state;

        let check_role = user.roles.filter((role_id) => {
            return role.id == role_id
        })

        let status = false
        if (check_role.length) {
            status = true
        }

        return (
            <CustomInput className="m-2" type="switch" id={role.id} name={role.id} data-id={role.id}
                label={role.name.toUpperCase()}
                onChange={(e) => this.handleChange(e)} checked={status} />
        )
    }


    render() {

        let userList = [];

        let paginator;
        if (this.state.hasOwnProperty('userList')) {
            userList = this.state.userList.data;
            paginator = {
                'currentPage': this.state.userList.current_page,
                'pagesCount': this.state.userList.last_page,
                'total': this.state.userList.total,
                'to': this.state.userList.to,
                'from': this.state.userList.from,
            }
        }

        const { id, name, gender, mobile, email, address, nid, status } = this.state.user;
        const { all_roles } = this.state;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }

        const pass_check = (this.state.password == this.state.password_confirmation) ? { color: 'green', border: '1px green solid' } : { color: 'red', border: '1px red solid' };


        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="User" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="6" md="6">

                                <Card>
                                    <CardBody>
                                        <CardTitle>Create New User</CardTitle>

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>
                                            <Card>
                                                <CardHeader>
                                                    <strong>User Role Information</strong>
                                                    <small> Form</small>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        <Label for="exampleCheckbox">Attach User Role</Label>
                                                        <div>
                                                            {all_roles.map((role) => {
                                                                return this.getRoleInput(role);
                                                            })}
                                                        </div>
                                                    </FormGroup>

                                                </CardBody>
                                            </Card>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label="User Name " type="text" name="name" id="name" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label="Mobile No " type="number" name="mobile" id="mobile" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label="Email" type="text" name="email" id="email" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label="Address" type="text" name="address" id="address" />
                                            </div>
                                            <div className="col-md-12">
                                                <AvField className="form-control" label="Password" type="password" name="password" id="password" onChange={(e) => this.handlePasswordChange(e)} />
                                            </div>

                                            <div className="col-md-12" >
                                                <AvField style={pass_check} className="form-control" label="Confirm Password" type="password" name="password_confirmation" id="password_confirmation" onChange={(e) => this.handlePasswordChange(e)} />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label="Status" type="checkbox" name="status" id="status" />
                                            </div>

                                            <div className=" mt-4">
                                                <Button type="submit" color="success">Submit</Button>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>User List</CardTitle>
                                        <div className="table-responsive-sm">
                                            <DataTabeSearch handleInputChange={this.handleInputChange} />
                                            <Table className="table  mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>User Name</th>
                                                        <th>Mobile No</th>
                                                        <th>Email</th>
                                                        <th>Address</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {userList.map((user, index) =>
                                                        <tr key={user.id.toString()}>
                                                            <td scope="row">{index + 1}</td>
                                                            <td>{user.name}</td>
                                                            <td>{user.mobile}</td>
                                                            <td>{user.email}</td>
                                                            <td>{user.address}</td>
                                                            <td><button type="button"
                                                                className="btn btn-sm btn-primary waves-effect waves-light"
                                                                onClick={() => { this.tog_scroll(user) }}
                                                                data-toggle="modal"
                                                            >Edit</button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                        <CardFooter className="p-6">
                                            <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                        </CardFooter>
                                    }
                                </Card>
                                <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">{name}</h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} ref={c => (this.form = c)}>
                                            {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    User Updated Successfully!
                                                </Alert>
                                            ) : null}
                                            <Card>
                                                <CardHeader>
                                                    <strong>User Role Information</strong>
                                                    <small> Form</small>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        <Label for="exampleCheckbox">Attach User Role</Label>
                                                        <div>
                                                            {all_roles.map((role) => {
                                                                return this.getRoleInput(role);
                                                            })}
                                                        </div>
                                                    </FormGroup>

                                                </CardBody>
                                            </Card>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label="User Name " type="text" name="name" id="name" value={name} />
                                                <AvField type="hidden" name="id" id="id" value={id} />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label="Mobile No " type="number" name="mobile" id="mobile" value={mobile} />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label="Email" type="text" name="email" id="email" value={email} />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label="Address" type="text" name="address" id="address" value={address} />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label="Password" type="password" name="password" id="password" onChange={(e) => this.handlePasswordChange(e)} />
                                            </div>

                                            <div className="col-md-12" >
                                                <AvField style={pass_check} className="form-control" label="Confirm Password" type="password" name="password_confirmation" id="password_confirmation" onChange={(e) => this.handlePasswordChange(e)} />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label="Status" type="checkbox" name="status" id="status" value={status == 1 ? true : false} />
                                            </div>


                                            <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </Modal>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.User;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createUser, editUser })(Users));
